import React from 'react'

import './styles.scss'

import Image1 from './images/Group_section4.svg'
import Image1M from './images/undraw_apartment_rent_o0ut.png'

const HomeSection2 = () => {
  return (
    <div className='home-section2'>
      <div className='home-section2-wrapper'>
        <h2 className="main-text">Premium New Amenity</h2>
        <p className="sub-text">Take luxury to the next level with our stress free grocery delivery service for your residents. Add new value to attract new residents and keep current ones happy.</p>
      </div>
      <img src={Image1} className="main-image" alt="section2 background" />
      <div className="main-image-mobile">
        <img src={Image1M} alt="section2 background" />
      </div>
    </div>
  )
}

export default HomeSection2