import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";

import './_styles.scss'

const Footer = ({
  history
}) => {
  useEffect(() => {
    function updateFooterPosition() {
      const { innerHeight: screenHeight } = window
      const bodyBottomPosition = document.querySelector('#main-body').getBoundingClientRect().bottom

      if (bodyBottomPosition < screenHeight) {
        document.querySelector('#footer').style.position = 'fixed'
      } else {
        document.querySelector('#footer').style.position = 'unset'
      }
    }
    const resizeObserver = new ResizeObserver(_ => {
      updateFooterPosition()
    })
    resizeObserver.observe(document.querySelector('#main-body'))
    return () => resizeObserver.disconnect()
  }, []);

  const toContactUs = () => {
    history.push('/terms#contact-us')
  }
  const toPrivacy = () => {
    history.push('/privacy')
  }
  const toTerms = () => {
    history.push('/terms')
  }
  return (
    <div id='footer'>
      <div className='footer-content'>
        <div className='footer-content-column'>
          <span>
            © 2021 Cart & Order, LLC. All rights reserved.
          </span>
        </div>
        <div className='footer-content-column footer-content-column-links'>
          <div className='footer-content-column-row'>
            <a onClick={toContactUs} href='javascript:;' className='a-tag'>Contact Us</a>
          </div>
          <div className='footer-content-column-row'>
            <a onClick={toTerms} href='javascript:;' className='a-tag'>Terms and Conditions</a>
          </div>
          <div className='footer-content-column-row'>
            <a onClick={toPrivacy} href='javascript:;' className='a-tag'>Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Footer)