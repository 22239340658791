export const regularEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regularPhoneDash = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
export const regularPhone = /^\d{9}(?:\d{1})?$/
export const regularZipcode = /^[0-9]{5}([- /]?[0-9]{4})?$/
export const validatePassword = (value, isPassword, watchValue, clearError) => {
  if (isPassword) {
    clearError("confirmPassword");
    return value === String(watchValue) || "Passwords don't match."
  } else {
    clearError("password");
    return value === String(watchValue) || "Passwords don't match."
  }
}
export const validateEmail = (value) => {
  if (!value.match(regularEmail)) {
    return "Email is not valid.";
  }
}
export const validatePhone = (value) => {
  if (!value.match(regularPhone) && !value.match(regularPhoneDash)) {
    return "Phone number is not valid.";
  }
}

export const validateZipcode = (value) => {
  if (!value.match(regularZipcode)) {
    return "Zip code is not valid.";
  }
}
