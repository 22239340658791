import {
  UPDATE_PERMISSION
} from './constants'

export const initState = {}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case UPDATE_PERMISSION:
      return {
        ...state,
        [payload.type]: {
          result: payload.result,
          data: payload.data ? {
            lat: payload.data.latitude,
            lng: payload.data.longitude,
            accuracy: payload.data.accuracy,
          } : undefined,
        },
      }

    default:
      return state
  }
}