import React from 'react'

import './styles.scss'

import ProgressButton from 'components/common/button/ProgressButton'

const InputGroup = ({
  label,
  placeholder,
  value,
  onChange,
  onClick,
  type,
  pattern,
  required,
  disabled,
  onButtonClick,
  buttonText,
  loading,
}) => {
  return (
    <div className='input-group-button' onClick={onClick}>
      {label && <p>{label}</p>}
      <div className="horizontal-group">
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type}
          pattern={pattern}
          required={required}
          disabled={disabled}
          autoComplete="off"
        />
        <ProgressButton
          classes="success"
          onClick={onButtonClick}
          disabled={disabled || !value}
          submitting={loading}
        >
          {buttonText}
        </ProgressButton>
      </div>
    </div>
  )
}

export default InputGroup