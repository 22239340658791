import React from "react";

import BaseModal from 'components/common/modal'
import Cart from 'components/Main/Cart'

import "./_styles.scss";

const CartModal = ({ open, handleClose }) => {
  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      className='cart-modal'
    >
      <Cart onCheckoutClick={handleClose} />
    </BaseModal>
  );
};

export default CartModal;
