
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import _ from 'lodash'
import { format as formatDate } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { withStyles, makeStyles, styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import StatusBadge from './StatusBadge';
import { getOrders } from 'services/orders'

import { renderMoney } from 'utils/helper'

import './_styles.scss'

const StyledTableThCell = styled(TableCell)({
  fontFamily: '"Source Sans Pro", sans-serif',
  fontWeight: 700,
  fontSize: '13px',
  color: '#7B8A96',
})

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#edf1f4',
    color: '#7b8a96',
  },
  body: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 14,
    fontWeight: 400,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f7f9fa',
      cursor: 'pointer',
    },
    color: '#4d4f5c',
    '&:hover': {
      backgroundColor: '#dedede',
    }
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 900,
  },
  tableContainer: {
    padding: 15,
  },
});

const OrderDetail = ({ order, onClick }) => (
  <Paper onClick={onClick} className="order">
    <div className="order-id">
      ORDER ID <b>{order.shortId}</b>
    </div>
    <div className="order-data">
      <div className="order-data-customer">
        <div className="order-data-customer-name">
          {order.firstName} {order.lastName}
        </div>
        <div className="order-data-customer-email">
          {order.email}
        </div>
      </div>
      <div className="order-data-total">
        ${renderMoney(order.finalTotal || order.total)}
      </div>
    </div>
    <div className="order-delivery">
      <div className="order-delivery-title">Pickup Time</div>
      <div>{order.deliveryTime}</div>
    </div>
    <Divider />
    <div className="order-status">
      <span>Order Status/Packed</span>
      <StatusBadge status={order.status} />
    </div>
  </Paper>
)

const OrderListPaper = ({
  orders,
  orderDetailCallback
}) => {
  return (
    orders.map((order, i) => (
      <OrderDetail
        order={order}
        onClick={() => orderDetailCallback(order._id)}
        key={order._id}
      />
    ))
  )
}

const OrderListTable = ({
  orders,
  orderDetailCallback,
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableThCell />
            <StyledTableThCell align="left">ORDER ID</StyledTableThCell>
            <StyledTableThCell align="left">TIME PLACED</StyledTableThCell>
            <StyledTableThCell align="left">DELIVERY TIME</StyledTableThCell>
            <StyledTableThCell align="left">TOTAL</StyledTableThCell>
            <StyledTableThCell align="left">STATUS</StyledTableThCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, i) => (
            <StyledTableRow key={order._id} onClick={() => orderDetailCallback(order._id)}>
              <StyledTableCell component="th" scope="row">
                {i + 1}
              </StyledTableCell>
              <StyledTableCell align="left">{order.shortId}</StyledTableCell>
              <StyledTableCell align="left">{order.createdAt}</StyledTableCell>
              <StyledTableCell align="left">{order.deliveryTime}</StyledTableCell>
              <StyledTableCell align="left">${renderMoney(order.finalTotal || order.total)}</StyledTableCell>
              <StyledTableCell align="left">
                <StatusBadge status={order.status} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const OpenOrder = ({
  buildings,
}) => {
  const classes = useStyles();
  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(0)
  const [buildingsObj, setBuildingsObj] = useState()
  const history = useHistory()
  useEffect(() => {
    if (buildings.length > 0) {
      const buildingsObj = buildings.reduce((acc, building) => {
        acc[building._id] = building
        return acc
      }, {})
      setBuildingsObj(buildingsObj)
    }
  }, [buildings])
  useEffect(() => {
    if (buildingsObj) init()
  }, [buildingsObj])
  const init = async () => {
    fetchData()
  }

  const fetchData = async (p = page) => {
    const res = (await getOrders(page)).data
    const orders = res.map(order => {
      let deliveryTime = order.deliveryTime ? formatDate(new Date(order.deliveryTime), 'MM/dd/yyyy, h:mm a') : ''
      if (deliveryTime) {
        const dropoffDuration = buildingsObj[order.building]?.dropoffDuration
        if (dropoffDuration) {
          const toTime = new Date(order.deliveryTime)
          toTime.setMinutes(toTime.getMinutes() + dropoffDuration)
          deliveryTime += ` - ${formatDate(toTime, 'h:mm a')}`
        }
      }
      return {
        ...order,
        storeName: _.get(order, 'store.name'),
        deliveryTime,
        createdAt: formatDate(new Date(order.createdAt), 'MM/dd/yyyy, h:mm a'),
      }
    })
    setOrders(orders)
  }

  const toOrderDetail = id => {
    history.push(`/orders/${id}`)
  }

  return (
    <Box className="open-order-page">
      <Box component="div" mb={2} px={3}>
        <Box
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          height="100%"
          py={2.5}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className="heading"
          >
            Orders
          </Box>
        </Box>
        <div className="orders">
          <div className="mobile">
            <OrderListPaper
              orders={orders}
              orderDetailCallback={toOrderDetail}
            />
          </div>
          <div className="desktop">
            <OrderListTable
              orders={orders}
              orderDetailCallback={toOrderDetail}
            />
          </div>
        </div>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ buildings }) => ({
  buildings: buildings.buildings,
})

export default connect(mapStateToProps)(OpenOrder)

