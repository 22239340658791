import axios from 'utils/axios'
import request from './base'

export const login = (email, password) => {
  return axios.post('/login', {
    email, password
  })
}

export const signup = ({
  firstName,
  lastName,
  phone,
  zipCode,
  email,
  password,
  building,
  buildingUnitNo,
}) => {
  return axios.post('/createUser', {
    firstName,
    lastName,
    phone,
    zipCode,
    email,
    password,
    building,
    buildingUnitNo,
  })
}

export const setAvatar = (userId, fileId) => {
  return request({
    url: `/users/${userId}/set_avatar`,
    method: 'POST',
    data: {
      image: fileId
    }
  })
}

export const updateProfile = (userId, data) => {
  return request({
    url: `/users/${userId}`,
    method: 'PUT',
    data
  })
}

export const loadData = userId => {
  return request({
    url: `/users/${userId}`,
  })
}

export const forgotPassword = (email) => {
  return request({
    url: `/users/forgot_password`,
    method: 'POST',
    data: { email }
  })
}

export const resetPassword = (email, token, newPassword) => {
  return request({
    url: `/users/reset_password`,
    method: 'POST',
    data: { email, token, newPassword }
  })
}

export const deleteAccount = () => {
  return request({
    url: `/users`,
    method: 'DELETE',
  })
}
