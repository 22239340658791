import React from 'react'

const HeaderUser = () => {
  return (
    <div className='header-item header-user'>
      <div className='header-item-icon'>
        <img src='/icons/noun_avatar.svg' alt='avatar' />
      </div>
    </div>
  )
}

export default HeaderUser