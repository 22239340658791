import { makeStyles } from '@material-ui/core/styles';
export default makeStyles({
    root: {
        maxWidth: 345,
        boxShadow: 'none',
    },
    cardBorder: {
        border: '0.5px #00000014 solid',
        '&:hover': {
            border: '1px #5fa01b solid',
            boxShadow: '0px 0px 5px -2px #5fa01b',
        }
    },
    extendedIcon: {
        fontSize: '1.5em',
        margin: '0 15px'
    },
    storeButtonLabel: {
        position: 'absolute',
        height: '100%',
    },
    cardActions: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    countShoppingCartIcon: {
        padding: '0',
        fontSize: '1rem'
    },
    counterCardFabButton: {
        boxShadow: 'none',
        borderRadius: '26px',
        height: '37px',
    },
    listHeader: {
        paddingTop: '0'
    },
    filterSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    price: {
        color: '#2b2a2a',
        fontSize: '1rem',
        fontWeight: 500
    },
    size: {
        color: '#2b2a2a',
        fontSize: '0.8rem',
        fontWeight: 500
    },
});