import { intl } from './intl'
import user from './user'
import cart from './cart'
import modals from './modals'
import store from './store'
import toast from './toast'
import permissions from './permissions'
import buildings from './buildings'
import common from './common'
import category from './category'

export default {
  intl,
  user,
  cart,
  modals,
  store,
  toast,
  permissions,
  buildings,
  common,
  category,
}