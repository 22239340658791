import React, { Fragment, useState, useEffect } from 'react'

import Autocomplete from '@material-ui/lab/Autocomplete'

export default ({ placeholder, options, labelKey, className, onChange, inputClassName, value }) => {
  return (
    <Fragment>
      {options && <Autocomplete
        options={options}
        getOptionLabel={(option) => option[labelKey]}
        renderInput={(params) => (
          <div className={className} ref={params.InputProps.ref}>
            <input
              placeholder={placeholder}
              {...params.inputProps}
              className={inputClassName}
              autoComplete="new-password"
            />
          </div>
        )}
        value={value || ''}
        onChange={(event, newInputValue) => {
          onChange(newInputValue);
        }}
        className="w-100"
      />}
    </Fragment>
  )
}
