import React from 'react'

import './styles.scss'

const Button = ({ children, classes = '', style, onClick, disabled = false, ...props }) => {
  return (
    <button
      className={`button ${classes}`}
      onClick={onClick} style={style}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button