import request from './base'

export const updateCart = (products) => {
  return request({
    method: 'PUT',
    url: '/cart',
    data: {
      cart: products,
    },
  })
}
