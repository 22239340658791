import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom'

import {
  NavItem,
  NavbarToggler,
  Nav,
} from 'reactstrap'

import HeaderCart from '../components/HeaderCart'
import HeaderUser from '../components/HeaderUser'
import CartModal from 'components/Modals/Cart'
import HelpModal from 'components/Modals/HelpModal'
import HowItWorkModal from 'components/Modals/HowItWork'

import useStyles from './style';
import './_styles.scss'

import {
  togglePickupCalendar as togglePickupCalendarState,
} from 'reducers/modals/actions'
import { logout as clearUserState } from 'reducers/user/actions'
import { clearCartAll as clearCartState } from 'reducers/cart/actions'
import {
  toggleCart as toggleCartState,
  toggleHelp as toggleHelpState,
  toggleHowItWork as toggleHowItWorkState,
} from 'reducers/modals/actions'

const Header = ({
  products,
  clearUser,
  toggleCart,
  toggle: toggleHeader,
  toggleHelp,
  openHelp,
  openHowItWork,
  toggleHowItWork,
  openCart,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null)
  const [itemCountInCart, setItemCountInCart] = useState(0)

  const history = useHistory()

  const toPathname = (pathname) => {
    if (history.location.pathname !== pathname) {
      history.push(pathname)
    }
  }
  const toStore = () => {
    setAnchorEl(null)
    toggleHeader()
    toPathname('/store')
  }

  const toAccount = () => {
    setAnchorEl(null)
    toggleHeader()
    toPathname('/account')
  }

  const toOrders = () => {
    setAnchorEl(null)
    toggleHeader()
    toPathname('/orders')
  }

  const updateCartCount = (products) => {
    setItemCountInCart(Object.keys(products).length)
  }
  useEffect(() => {
    updateCartCount(products)
  }, [products])

  const toggleCartModal = open => {
    toggleCart(open)
    toggleHeader()
  }

  const logoutClick = async () => {
    await clearUser()
    setAnchorEl(null)
    toggleHeader()
    history.push('/')
  }

  const toggleHelpModal = open => {
    toggleHelp(open)
    setAnchorEl(null)
    toggleHeader()
  }

  const toggleHowItWorkModal = open => {
    toggleHowItWork(open)
    setAnchorEl(null)
    toggleHeader()
  }

  return (
    <>
      <div className="authed-menu">
        <div className="mobile-cart" onClick={() => toggleCartModal(true)}>
          <HeaderCart itemCountInCart={itemCountInCart} withText={false} />
        </div>
        <NavbarToggler onClick={event => setAnchorEl(event.currentTarget)} />
      </div>
      <Nav navbar className="authed-nav">
        <NavItem className="authed-item" onClick={() => toggleCartModal(true)}>
          <HeaderCart itemCountInCart={itemCountInCart} />
        </NavItem>
        <NavItem className="authed-item" onClick={event => setAnchorEl(event.currentTarget)}>
          <HeaderUser />
        </NavItem>
      </Nav>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{
          paper: classes.profileDropdown
        }}
      >
        <MenuItem onClick={toStore} classes={{ root: classes.profileMenuItem }}>
          <div className={classes.profileMenuItemInner}>
            Store
          </div>
        </MenuItem>
        <MenuItem onClick={toAccount} classes={{ root: classes.profileMenuItem }}>
          <div className={classes.profileMenuItemInner}>
            Account
          </div>
        </MenuItem>
        <MenuItem onClick={toOrders} classes={{ root: classes.profileMenuItem }}>
          <div className={classes.profileMenuItemInner}>
            Orders
          </div>
        </MenuItem>
        <MenuItem onClick={() => toggleHowItWorkModal(true)} classes={{ root: classes.profileMenuItem }}>
          <div className={`${classes.profileMenuItemInner}`}>
            How It Works
          </div>
        </MenuItem>
        <MenuItem onClick={() => toggleHelpModal(true)} classes={{ root: classes.profileMenuItem }}>
          <div className={`${classes.profileMenuItemInner}`}>
            Help
          </div>
        </MenuItem>
        <MenuItem onClick={logoutClick} classes={{ root: classes.profileMenuItem }}>
          <div className={`${classes.profileMenuItemInner} ${classes.profileMenuItemInnerLastChild}`}>
            Logout
          </div>
        </MenuItem>
      </Menu>
      <CartModal open={openCart} handleClose={() => toggleCartModal(false)} />
      <HelpModal open={openHelp} handleClose={() => toggleHelpModal(false)} />
      <HowItWorkModal open={openHowItWork} handleClose={() => toggleHowItWorkModal(false)} />
    </>
  );
}

const mapStateToProps = ({ cart, modals, user }) => ({
  products: cart.goods,
  newStore: cart.store,
  openPickupCalendar: modals.pickupCalendar,
  user,
  openCart: modals.cart.open,
  openHelp: modals.help.open,
  openHowItWork: modals.howItWork.open,
})

const mapDispatchToProps = dispatch => ({
  togglePickupCalendar: open => dispatch(togglePickupCalendarState({ open })),
  clearUser: () => dispatch(clearUserState()),
  clearCart: () => dispatch(clearCartState()),
  toggleCart: open => dispatch(toggleCartState({ open })),
  toggleHelp: open => dispatch(toggleHelpState({ open })),
  toggleHowItWork: open => dispatch(toggleHowItWorkState({ open })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
