import React from 'react'
import { connect } from 'react-redux'

import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from 'reactstrap'

import LoginModal from 'components/Modals/LoginModal/LoginModal'
import SignUpModal from 'components/Modals/SignUpModal/SignUpModal'
import ForgotPasswordModal from 'components/Modals/ForgotPasswordModal/ForgotPasswordModal'

import {
  toggleLogin as toggleLoginState,
  toggleSignup as toggleSignupState,
  toggleForgotPassword as toggleForgotPasswordState,
} from 'reducers/modals/actions'

const Header = ({
  openLogin,
  openSignUp,
  openForgotPwd,
  toggleLogin,
  toggleSignup,
  toggleForgotPassword,
  toggle: toggleHeader,
  isCollapseOpen,
}) => {
  const handleOpenLogin = () => {
    toggleLogin(true)
    toggleHeader()
  };
  const handleCloseLogin = () => {
    toggleLogin(false)
  };

  const handleOpenSignUp = () => {
    toggleSignup(true);
    toggleHeader()
  };
  const handleCloseSignUp = (item) => {
    toggleSignup(false);
  };
  const handleCloseForgotPassword = () => {
    toggleForgotPassword(false)
  }

  const toSignup = () => {
    toggleLogin(false)
    toggleForgotPassword(false)
    toggleSignup(true)
  }
  const toLogin = () => {
    toggleSignup(false)
    toggleForgotPassword(false)
    toggleLogin(true)
  }
  const toForgotPwd = () => {
    toggleSignup(false)
    toggleLogin(false)
    toggleForgotPassword(true)
  }

  return (
    <>
      <NavbarToggler onClick={toggleHeader} />
      <Collapse isOpen={isCollapseOpen} navbar className='justify-content-end px-4 header-right'>
        <Nav navbar className="unauthed-nav">
          <NavItem className="notification-item" onClick={handleOpenLogin}>
            <div className="nav-signup">
              Login
            </div>
          </NavItem>
          <NavItem className="notification-item" onClick={handleOpenSignUp}>
            <div className="nav-login">
              Sign Up
            </div>
          </NavItem>
          <LoginModal open={openLogin} handleClose={handleCloseLogin} toSignup={toSignup} toForgotPassword={toForgotPwd} />
          <SignUpModal open={openSignUp} handleClose={handleCloseSignUp} toLogin={toLogin} />
          <ForgotPasswordModal open={openForgotPwd} handleClose={handleCloseForgotPassword} toLogin={toLogin} />
        </Nav>
      </Collapse>
    </>
  )
}

const mapStateToProps = ({ modals }) => ({
  openLogin: modals.login.open,
  openSignUp: modals.signup.open,
  openForgotPwd: modals.forgotPassword.open,
})

const mapDispatchToProps = dispatch => ({
  toggleLogin: open => dispatch(toggleLoginState({ open })),
  toggleSignup: open => dispatch(toggleSignupState({ open })),
  toggleForgotPassword: open => dispatch(toggleForgotPasswordState({ open }))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
