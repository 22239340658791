import {
  TOGGLE_NEARBY_STORE,
  TOGGLE_WARNING,
  TOGGLE_PICKUP_CALENDAR,
  TOGGLE_CART,
  TOGGLE_LOGIN,
  TOGGLE_SIGNUP,
  TOGGLE_FORGOT_PASSWORD,
  TOGGLE_HELP,
  TOGGLE_HOW_IT_WORK,
} from './constants'

export const toggleNearbyStore = ({ open, zipCode }) => {
  return {
    type: TOGGLE_NEARBY_STORE,
    payload: {
      open, zipCode
    },
  }
}

export const toggleWarning = ({
  open,
  withCancel,
  callback,
  message,
  okText = 'OK',
  cancelText = 'Cancel'
}) => {
  return {
    type: TOGGLE_WARNING,
    payload: {
      open, withCancel, callback, message, okText, cancelText,
    },
  }
}

export const togglePickupCalendar = ({ open }) => {
  return {
    type: TOGGLE_PICKUP_CALENDAR,
    payload: {
      open
    },
  }
}

export const toggleCart = ({ open }) => {
  return {
    type: TOGGLE_CART,
    payload: {
      open
    },
  }
}

export const toggleLogin = ({ open }) => {
  return {
    type: TOGGLE_LOGIN,
    payload: {
      open
    },
  }
}

export const toggleSignup = ({ open }) => {
  return {
    type: TOGGLE_SIGNUP,
    payload: {
      open
    },
  }
}

export const toggleForgotPassword = ({ open }) => {
  return {
    type: TOGGLE_FORGOT_PASSWORD,
    payload: {
      open
    },
  }
}

export const toggleHelp = ({ open }) => {
  return {
    type: TOGGLE_HELP,
    payload: {
      open
    },
  }
}

export const toggleHowItWork = ({ open }) => {
  return {
    type: TOGGLE_HOW_IT_WORK,
    payload: {
      open
    },
  }
}
