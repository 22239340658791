import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { format as formatDate } from 'date-fns'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import { getOrderDetail, updateOrderItem, checkCancellation, cancelOrder } from 'services/orders'

import OrderItem from './OrderItem'
import StatusBadge from 'components/Main/Orders/StatusBadge'
import DataChanged from 'components/common/data-changed'
import Button from 'components/common/button'
import { renderMoney } from 'utils/helper'
import { openToast } from 'reducers/toast/actions'
import { toggleWarning } from 'reducers/modals/actions'
import { itemStatuses } from 'utils/helper'

import './_Receipt.scss'

function OrderDetail({ user, match, history, buildings, dispatch }) {
  const [order, setOrder] = useState({})
  const [submittingCancel, setSubmittingCancel] = useState(false)

  const fetchData = useCallback(async () => {
    const orderId = match.params.id
    return (await getOrderDetail(orderId)).data
  }, [match.params.id])
  const handleData = useCallback((order, buildings) => {
    if (!order?._id) return
    const data = {
      ...order,
      orderDateFormatted: formatDate(new Date(order.createdAt), 'MMMM dd, yyyy'),
    }
    if (buildings) {
      let deliveryTime = order.deliveryTime ? formatDate(new Date(order.deliveryTime), 'MM/dd/yyyy, h:mm a') : ''
      if (deliveryTime) {
        const dropoffDuration = buildings[order.building]?.dropoffDuration
        if (dropoffDuration) {
          const toTime = new Date(order.deliveryTime)
          toTime.setMinutes(toTime.getMinutes() + dropoffDuration)
          deliveryTime += ` - ${formatDate(toTime, 'h:mm a')}`
        }
      }
      data.deliveryTime = deliveryTime
    }
    setOrder(data)
  }, [])

  const onBuildingsLoaded = useCallback(async () => {
    const buildingsObj = buildings.reduce((acc, building) => {
      acc[building._id] = building
      return acc
    }, {})
    const order = await fetchData()
    handleData(order, buildingsObj)
  }, [buildings, fetchData, handleData])

  useEffect(() => {
    if (buildings.length > 0) {
      onBuildingsLoaded()
    }
  }, [buildings, onBuildingsLoaded])


  const onItemUpdate = async (itemId, data) => {
    const itemIndex = order.orderItems.findIndex(i => i._id === itemId)
    if (!order.orderItems[itemIndex]) return
    const orderId = match.params.id
    const res = await updateOrderItem(orderId, itemId, data)
    if (res.result !== 'success') return
    const orderItem = res.data
    order.orderItems[itemIndex] = orderItem
    order.subtotal = orderItem.order.subtotal
    order.tax = orderItem.order.tax
    order.total = orderItem.order.total
    setOrder({
      ...order,
      orderItems: order.orderItems,
    })
    dispatch(openToast({
      message: 'Item Updated!',
      type: 'success',
    }))
  }

  const onCheckCancellation = async () => {
    setSubmittingCancel(true)
    let res
    try {
      res = await checkCancellation(order._id)
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      dispatch(openToast({
        message: err.message,
        type: 'error',
      }))
      setSubmittingCancel(false)
      return
    }
    setSubmittingCancel(false)
    if (!res.data.cancellable) {
      return dispatch(toggleWarning({
        open: true,
        message: `We cannot cancel this order, it has been delivered.`,
        okText: 'Yes',
        cancelText: 'No',
      }))
    }
    let message
    if (res.data.fee === 0) {
      message = 'Are you sure to cancel this order?'
    } else {
      message = `Your order is currently being ${res.data.status}, a $${renderMoney(res.data.fee)} fee will be charged to cancel this order.`
    }
    dispatch(toggleWarning({
      open: true,
      withCancel: true,
      callback: () => onCancelOrder(),
      message,
      okText: 'Yes',
      cancelText: 'No',
    }))
  }
  const onCancelOrder = async () => {
    setSubmittingCancel(true)
    let res
    try {
      res = await cancelOrder(order._id)
      if (res.result !== 'success') throw new Error(res.err)
    } catch (err) {
      dispatch(openToast({
        message: err.message,
        type: 'error',
      }))
      setSubmittingCancel(false)
      return
    }
    const newOrder = res.data

    setOrder({
      ...order,
      status: newOrder.status,
      paymentStatus: newOrder.paymentStatus,
      finalTotal: newOrder.finalTotal,
      fee: newOrder.fee,
    })
    setSubmittingCancel(false)
  }

  const isCancelDisabled = () => {
    return submittingCancel || !order || order.status === 'CANCELLED' || order.paymentStatus === 'COMPLETED'
  }
  const allowEditOrderItem = () => {
    return order.open && !['CANCELLED', 'DELIVERED'].includes(order.status) && order.paymentStatus !== 'COMPLETED'
  }

  const getPriceChangedReasons = () => {
    if (!order.finalTotal || order.total === order.finalTotal) return
    const reasons = {
      'Adjusted Weight': false,
      'Out of Stock Items': false,
    }
    order.orderItems.forEach(item => {
      if (item.status === itemStatuses.OUT_OF_STOCK) return reasons['Out of Stock Items'] = true
      if (item.weight && Number(item.weight) != Number(item.quantity)) return reasons['Adjusted Weight'] = true
    })
    const strReasons = Object.entries(reasons).filter(([_, value]) => value).map(([key]) => key).join(', ')
    return strReasons && `* ${strReasons}`
  }

  const orderFee = () => {
    return order.fixedFee + order.shippingFee
  }

  return (
    <div className="OrderDetail container-fluid">
      <div className="heading">
        <ArrowBackIcon className="back-btn" onClick={() => history.push('/orders')} />
        <h4>Order Detail</h4>
        <div className="order-actions">
          <Button
            classes="error"
            disabled={isCancelDisabled()}
            onClick={onCheckCancellation}
          >
            Cancel Order
          </Button>
        </div>
      </div>
      <div className="invoice-detail">
        <div className="detail-row">
          <div className="detail-col">
            <p>
              Order/Invoice ID: <span>{order.shortId}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Order Date: <span>{order.orderDateFormatted}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Order Status: <span><StatusBadge status={order.status} /></span>
            </p>
          </div>
        </div>
        <div className="detail-row">
          <div className="detail-col">
            <p>
              Customer: <span>{order.firstName} {order.lastName}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Payment Method: <span className="text-capitalize">{order.paymentMethod}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Order Amount: <span>${order.total}</span>
            </p>
          </div>
        </div>
        <div className="detail-row order_two_data">
          <div className="detail-col">
            <p>
              Customer Email: <span>{user.email}</span>
            </p>
            <p>
              Customer Phone: <span>{user.phone}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Delivery Date: <span>{order.deliveryTime}</span>
            </p>
          </div>
          <div className="detail-col">
            <p>
              Fee: <span>${orderFee()}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="invoice-detail">
        <div className="table-container">
          <table className="table products-list">
            <thead>
              <tr>
                <th colSpan="1">Status</th>
                <th colSpan="2">Product</th>
                <th colSpan="1">Size</th>
                <th colSpan="1">Price</th>
                <th colSpan="1">QTY</th>
                <th colSpan="1">Tax</th>
                <th colSpan="1">Total</th>
              </tr>
            </thead>
            <tbody>
              {order.orderItems && order.orderItems.filter(i => i.quantity > 0).map((item, n) => {
                return (
                  <OrderItem
                    item={item}
                    key={n}
                    index={n}
                    updateItem={onItemUpdate}
                    orderAllowedEdit={allowEditOrderItem()}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="total-amounts">
          <p>
            Sub-total: <span>
              $<DataChanged
                oldData={renderMoney(order.subtotal)}
                newData={renderMoney(order.finalSubtotal)}
              />
            </span>
          </p>
          <p>
            Delivery Fee: <span>${renderMoney(order.shippingFee)}</span>
          </p>
          <p>
            Taxes: <span>
              $<DataChanged
                oldData={renderMoney(order.tax)}
                newData={renderMoney(order.finalTax)}
              />
            </span>
          </p>
          <h4>
            Total: <span>
              $<DataChanged
                oldData={renderMoney(order.total)}
                newData={renderMoney(order.finalTotal)}
              />
            </span>
          </h4>
          <span><i>{getPriceChangedReasons()}</i></span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user, buildings, common }) => ({
  user,
  buildings: buildings.buildings,
  orderConstants: common.orderConstants,
})

export default connect(mapStateToProps)(OrderDetail)
