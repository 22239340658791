import React from 'react';
import { withRouter } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
} from '@fortawesome/free-regular-svg-icons';

import InputStyle2 from 'components/common/inputs/style2'
import BaseModal from 'components/common/modal'
import Button from 'components/common/button'

import './_styles.scss'

import { login } from 'services/user'

import { userAndBuildingLoaded } from 'utils/state_init'

class Loginmodal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      show: false,
      type: 'password',
      error: '',
    };
  }
  emailHandler = (event) => {
    this.setState({ email: event.target.value });
  }
  passwordHandler = (event) => {
    this.setState({ password: event.target.value });
  }
  showHandler = (event) => {
    event.preventDefault();
    this.setState({ show: true });
    this.setState({ type: 'text' });
  }
  hideHandler = (event) => {
    event.preventDefault();
    this.setState({ show: false });
    this.setState({ type: 'password' });
  }

  onLogin = async (e) => {
    e.preventDefault()
    const res = await login(this.state.email, this.state.password)
    if (res.result !== 'success') {
      return this.setState({
        error: res.err
      })
    }
    const { handleClose } = this.props
    // dispatch
    userAndBuildingLoaded({ user: res.data })
    handleClose()
    this.props.history.push('/store')
  }

  toSignup = e => {
    e.preventDefault()
    this.props.toSignup()
  }
  toForgotPassword = e => {
    e.preventDefault()
    this.props.toForgotPassword()
  }
  onClose = () => {
    this.props.handleClose()
  }

  render() {
    const {
      open,
    } = this.props
    return (
      <BaseModal
        open={open}
        onClose={this.onClose}
        className='login-modal'
      >
        <div role="dialog" aria-labelledby="exampleModalCenterTitle" className='login-container'>
          <div className="login_header text-center">

            <h4 className="mb-3">Log in</h4>
          </div>
          <div className="modal-body">
            <div className="form mt-3">
              <form onSubmit={this.onLogin}>
                <div className="input-group form-group position-relative">
                  <InputStyle2 type="email" id="emails" onChange={this.emailHandler} name="emails" placeholder="E-mail" />
                </div>
                <div className="input-group form-group position-relative" id="show_hide_password">
                  <InputStyle2 onChange={this.passwordHandler} type={this.state.type} placeholder="Password" />
                  <div className="input-group-addon position-absolute show-hide-pwd">
                    {this.state.show === false && <a href="#" onClick={this.showHandler} className="black_color"><FontAwesomeIcon icon={faEyeSlash} color='#808080' /></a>}
                    {this.state.show === true && <a href="#" onClick={this.hideHandler} className="black_color"><FontAwesomeIcon icon={faEye} color='#808080' /></a>}
                  </div>
                </div>
                {this.state.error && (
                  <p style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</p>
                )}
                <div className="form-group submit_button mt-2">
                  <Button
                    classes='success'
                    style={{
                      height: '56px',
                      fontSize: '13px',
                      fontWeight: 600,
                    }}
                    // onClick={this.onLogin}
                    type="submit"
                  >
                    Sign in
                  </Button>
                </div>
                <div className="form-group d-flex align-items-center justify-content-center">
                  <div className="a-tag forgot_pswd float-right" onClick={this.toForgotPassword}>Forgot password?</div>
                </div>
              </form>
              <div className="text-center mt-4">
                <span>Not a member yet?</span> <div className="a-tag signup_btn black_color" onClick={this.toSignup}>Sign up</div>
              </div>
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
}

export default withRouter(Loginmodal);
