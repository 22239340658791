import React, { useState } from 'react';
import BaseModal from 'components/common/modal'
import HowItWork from 'components/Main/Landing1/components/section5'

import './_styles.scss'

const HowItWorkModal = ({
  open,
  handleClose,
}) => {
  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      className='how-it-work-modal'
    >
      <div className="modal-body">
        <HowItWork />
      </div>
    </BaseModal>
  );
}

export default HowItWorkModal
