import React from 'react'

import './styles.scss'

import Image1 from './images/simple_onboard.png'

const HomeSection4 = () => {
  return (
    <div className='home-section4'>
      <div className='home-section4-wrapper'>
        <h2 className="main-text">Simple Onboarding</h2>
        <p className="sub-text">There is no software or business dashboards to learn. We provide full customer support to staff members and residents. Buildings just select the day(s) and time they want groceries delivered to their residents.</p>
      </div>
      <div className="main-image">
        <img src={Image1} alt="section4 background" />
      </div>
      <div className="home-section4-background" />
    </div>
  )
}

export default HomeSection4