export const getRandomNum = (min, max) => Math.floor(min + Math.random() * (max + 1 - min))

export const getFullPrice = arr => arr.reduce((acc, { price, quantity }) => acc + (price * quantity), 0)

export const getAllTypes = array => [...new Set(array.map(({ type }) => type))].sort()

export const addArrayItem = (allItems, item) => {
  allItems.push(item)
  return [...allItems]
}

export const replaceArrayItem = (array, newItem) => {
  const oldItemIndex = array.findIndex(({ id }) => id === newItem.id)

  return [...array.slice(0, oldItemIndex), newItem, ...array.slice(oldItemIndex + 1)]
}

export const changeItemQuantity = (allItems, itemId, itemQuantity) => {
  const oldItem = {
    ...allItems.find(({ id }) => id === itemId)
  }

  oldItem.quantity = itemQuantity

  return replaceArrayItem(allItems, oldItem)
}

export const removeArrayItem = (allItems, itemId) => {
  const itemIndex = allItems.findIndex(({ id }) => id === itemId)

  if (itemIndex === -1) {
    return []
  }

  return [...allItems.slice(0, itemIndex), ...allItems.slice(itemIndex + 1)]
}

// fake data
export const generateData = () => new Array(getRandomNum(5, 15))
  .fill('')
  .map((elem, i) => ({
    id: i ** 2,
    type: [
      'Fruit',
      'Candy',
      'Vegetable'
    ][Math.floor(Math.random() * 3)],
    avatar: `https://i.picsum.photos/id/${getRandomNum(1, 100)}/200/200.jpg`,
    name: 'Product name',
    quantity: getRandomNum(1, 4),
    price: getRandomNum(100, 999),
  }))

// get cart data from for a specific store
export const getStoreCart = (cart, storeId) => {
  const newCart = {}
  for (let key in cart) {
    if (cart[key].store === storeId) newCart[key] = cart[key]
  }
  return { ...newCart }
}

export const calculateDistance = ({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }) => {
  const R = 6371e3 // metres
  const φ1 = lat1 * Math.PI / 180 // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180
  const Δφ = (lat2 - lat1) * Math.PI / 180
  const Δλ = (lng2 - lng1) * Math.PI / 180

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  // 1 km = 0.621371192 miles
  return Math.round((R * c * 0.621371192) / 10) / 100
}
export const reformatPhone = (input) => {
  if (!input) return ''
  let output = ''
  input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
    if (g1.length) {
      output += g1
      if (g1.length === 3) {
        if (g2.length) {
          output = `(${g1}) ${g2}`
          if (g2.length === 3) {
            if (g3.length) {
              output += '-' + g3
            }
          }
        }
      }
    }
  })
  return output
}

export const reformatZipcode = (input) => input.replace(/\D+/gi, '').substr(0, 5)

export const roundMoney = n => Math.round(n * 100) / 100

export const calculateOrderItemTax = ({
  TAX_UNITS,
  price,
  quantity,
  taxUnit,
  tax,
}) => {
  let res = 0
  if (tax > 0) {
    if (taxUnit === TAX_UNITS.FIXED) {
      res = tax * quantity
    }
    if (taxUnit === TAX_UNITS.PERCENT) {
      res = price * tax / 100 * quantity
    }
  }
  return roundMoney(res)
}
export const calculateOrderTax = (products, TAX_UNITS) => {
  return products.reduce((acc, product) => {
    return acc + calculateOrderItemTax({
      TAX_UNITS,
      ...product
    })
  }, 0)
}

export const renderMoney = n => !isNaN(n) ? Number(n).toFixed(2) : undefined

export const orderStatuses = {
  PROCESSING: 'Processing',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
}
export const orderStatusMapping = {
  NEW: orderStatuses.PROCESSING,
  PACKING: orderStatuses.PROCESSING,
  PACKED: orderStatuses.PROCESSING,
  DELIVERED: orderStatuses.DELIVERED,
  CANCELLED: orderStatuses.CANCELLED,
}
export const getMappedOrderStatus = status => {
  return orderStatusMapping[status] || orderStatuses.PROCESSING
}

export const itemStatusesText = {
  PROCESSING: 'Processing',
  PACKED: 'Packed',
  OUT_OF_STOCK: 'Out of stock',
  REMOVED: 'Removed',
}

export const itemStatuses = Object.keys(itemStatusesText).reduce((statuses, status) => {
  statuses[status] = status
  return statuses
}, {})
export const getMappedItemStatus = status => {
  return itemStatusesText[status] || itemStatusesText.PROCESSING
}

export const shuffleArray = arr => {
  const newArr = []
  while (arr.length > 0) {
    const randomIndex = Math.floor(Math.random() * 100) % arr.length
    const [element] = arr.splice(randomIndex, 1)
    newArr.push(element)
  }
  return newArr
}