import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const HelpAccordion = () => {
  return (
    <div className="help-accordions">
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4>Ordering</h4>
        </AccordionSummary>
        <AccordionDetails>
          Shop online on Cart & Order for your everyday grocery products. At checkout, select your preferred delivery window that is allotted by your building. Place your order and delivery will be made carefully and promptly at your chosen time.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h4>Fees</h4>
        </AccordionSummary>
        <AccordionDetails>
          The ONLY fee we charge is a $5.99 delivery fee for orders over $30, and $7.49 for orders under $30, unless you cancel an order in the process of being delivered. No tips or other fees!
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <h4>Where We Shop</h4>
        </AccordionSummary>
        <AccordionDetails>
          We carefully shop at most local large grocery stores closest to you to make sure delivery is on time.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Update Order</h4>
        </AccordionSummary>
        <AccordionDetails>
          You may update your order anytime two hours before your earliest delivery window. This includes adding items and removing items as well as cancelling your order. To add items to an existing order, shop as usual and at check out select the date/time you have selected for the order you want to add your new items too. If you select a different time/date a new order will be started.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Weighted Items</h4>
        </AccordionSummary>
        <AccordionDetails>
          Many items in store are packaged goods, however items such as produce, meats, deli, seafood, and various other items are pre packaged or packed on the spot with no exact weight. Cart & Order will make the best attempt to buy to as close to the pound/ounces as possible. You will be charged according to the exact weight of the item that is purchased, and this will be reflected on your order receipt. This price may be more or less than you originally purchased.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Pricing</h4>
        </AccordionSummary>
        <AccordionDetails>
          Prices are higher than your in store prices.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Taxes</h4>
        </AccordionSummary>
        <AccordionDetails>
          All items will be taxed according to local laws. This includes bottle deposit and bag fees.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Cancel Order</h4>
        </AccordionSummary>
        <AccordionDetails>
          You may cancel your order at any point without penalty prior to delivery. If your order is in the process of delivery a $5.00 cancellation fee will be charged. Go to orders from the menu, select the order you want to cancel, and select the cancel button.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Refunds/Returns</h4>
        </AccordionSummary>
        <AccordionDetails>
          If there is a problem with your order, please let us know immediately. You can make a request up to three days after your order has been sent. We will try everything we can to make things right. If items are missing or spoiled, a refund will be issued right away. Refunds may take up to ten business day to reflect in your bank account.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Messaging</h4>
        </AccordionSummary>
        <AccordionDetails>
          Your phone number will only be used when we have an inquiry about your order(s).
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Product</h4>
        </AccordionSummary>
        <AccordionDetails>
          No picture?  It's ok we should still be able to get this item if it's in stock.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Item Size</h4>
        </AccordionSummary>
        <AccordionDetails>
          Due to ongoing manufacturer changes, item sizes may be slightly off.
        </AccordionDetails>
      </Accordion>
      <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Out of Stock</h4>
        </AccordionSummary>
        <AccordionDetails>
          We will try our best to fulfill your order, however do to supply chain issues, some items may not be available.
        </AccordionDetails>
      </Accordion>
      {/* <Accordion className="help-question">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="disabled" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h4>Mobile App</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className='mr-4'>
            <a href='https://play.google.com/store/search?q=cart%20and%20order&c=apps' target='_blank' rel='noopener noreferrer'>
              <img src='/images/android.svg' alt='android version' />
            </a>
          </div>
          <div>
            <a href='https://www.apple.com/vn/search/cart-and-order?src=itunes_serp' target='_blank' rel='noopener noreferrer'>
              <img src='/images/ios.svg' alt='ios version' />
            </a>
          </div>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}

export default HelpAccordion
