import React, { Fragment } from 'react'

export default ({ oldData, newData, bold = true }) => <Fragment>
  {newData && newData !== oldData ? (
    <Fragment>
      <del>&nbsp;{oldData}&nbsp;</del>
          &nbsp;&rarr;&nbsp;
      {bold ? <b>{newData}</b> : <span>{newData}</span>}
    </Fragment>
  ) : oldData}
</Fragment>