import React from 'react'

import './_styles.scss'

const Row = ({ title, value, className }) => {
  return (
    <div className={`cart-summary-block ${className || ''}`}>
      <span className='summary-row-title'>{title}</span>
      <span className='summary-row-amount'>{value}</span>
    </div>
  )
}

export default Row