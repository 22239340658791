export const initState = { locale: 'en' }

export const intlActionTypes = {
    SET: '@locale/set',
    RESET: '@locale/reset',
}

export const intl = (state = initState, { type, locale }) => {
    switch (type) {
        case intlActionTypes.SET:
            return { ...state, locale }

        case intlActionTypes.RESET:
            return initState

        default:
            return state
    }
}