import React, { useState } from 'react'
import { connect } from 'react-redux'
import Input from 'components/common/inputs/style4'
import ProgressButton from 'components/common/button/ProgressButton'
import { useForm } from 'react-hook-form'

import { validateEmail } from 'utils/input_validator'

import { createInquiry } from 'services/inquiries'
import { toggleWarning } from 'reducers/modals/actions'

import { reformatPhone } from 'utils/helper'
import './styles.scss'

const InquiryForm = ({ openWarningModal }) => {
  const { register, handleSubmit, errors, reset, watch } = useForm({});

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (payload) => {
    setSubmitting(true)
    const data = await createInquiry(payload)
    setSubmitting(false)
    if (data.result !== 'success') {
      return
    }
    openWarningModal({
      callback: () => { },
      message: 'Your Inquiry Submitted Successfully.'
    })
    reset()
  }

  const onPhoneChange = e => {
    e.target.value = reformatPhone(e.target.value)
  }

  return (
    <div className='form-panel'>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="false" name="inquiry">
        <div className='form-row'>
          <Input
            type="text"
            label='First Name'
            refInput={register({
              required: true,
            })}
            name="firstName"
            className={errors.firstName && 'error'}
            value={watch('firstName')}
            error={errors.firstName}
          />
          <Input
            type="text"
            label='Last Name'
            refInput={register({
              required: true,
            })}
            name="lastName"
            className={errors.lastName && 'error'}
            value={watch('lastName')}
            error={errors.lastName}
          />
        </div>
        <div className='form-row'>
          <Input
            label='Email Address'
            refInput={register({
              required: true,
              validate: (value) => validateEmail(String(value))
            })}
            name="email"
            className={errors.email && 'error'}
            value={watch('email')}
            error={errors.email}
          />
          <Input
            label='Phone Number'
            refInput={register({
              pattern: {
                value: "^D*(d{3})D*(d{3})D*(d{4})",
                message: 'Invalid phone format'
              },
            })}
            name="phoneNumber"
            className={errors.phoneNumber && 'error'}
            value={watch('phoneNumber')}
            error={errors.phoneNumber}
            onChange={onPhoneChange}
          />
        </div>
        <div className='form-row'>
          <Input
            label='Building'
            refInput={register({
              required: true,
            })}
            name="building"
            className={errors.building && 'error'}
            value={watch('building')}
            error={errors.building}
          />
        </div>
        <div className='form-row'>
          <Input
            label='Complete Address'
            refInput={register({
              required: true,
            })}
            name="address"
            className={errors.address && 'error'}
            value={watch('address')}
            error={errors.address}
          />
        </div>
        {/* <div className='form-row'>
        <Checkbox label='I agree with terms and conditions' />
      </div> */}
        <div className='form-row submit'>
          <ProgressButton
            classes='success'
            type="submit"
            submitting={submitting}
          >
            Submit
          </ProgressButton>
        </div>
      </form>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})

export default connect(null, mapDispatchToProps)(InquiryForm)