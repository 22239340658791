import {
  SET_STATES,
  SET_PRODUCT_CONSTANTS,
  SET_ORDER_CONSTANTS,
} from './constants'

export const initState = {
  usStates: [],
  productConstants: null,
  orderConstants: {},
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_STATES:
      return {
        ...state,
        usStates: Object.entries(payload).map(state => ({
          id: state[0],
          name: state[1]
        })),
      }
    case SET_PRODUCT_CONSTANTS:
      return {
        ...state,
        productConstants: payload,
      }

    case SET_ORDER_CONSTANTS:
      return {
        ...state,
        orderConstants: payload,
      }

    default:
      return state
  }
}
