import React, { useState } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import {
  CardActions,
  Typography,
  IconButton,
} from '@material-ui/core'
import {
  Add,
  Remove,
} from '@material-ui/icons'

import './styles.scss';

// actions
import { changeQuantity } from 'reducers/cart/actions'
import { openToast as openToastState } from 'reducers/toast/actions'

const ProductPlaceHolderImg = '/images/product-placeholder.png'
const VALUES = {
  MIN: 1,
  MAX: 999,
};

const GoodsCard = ({
  id,
  image,
  name,
  quantity,
  price,
  unit,
  unitValue,
  changeItemQuantity,
  inventory,
  openToast
}) => {
  const [itemQuantity, setItemQuantity] = useState(quantity);
  // const onItemQuantityChange = e => setItemQuantity(e.target.value);
  const increaseItemQuantity = () => {
    if (inventory >= 0 && inventory <= quantity) {
      return openToast(`Only ${inventory} available`)
    }
    const newQuantity = itemQuantity + 1
    changeItemQuantity(id, newQuantity)
    setItemQuantity(newQuantity);
  }
  const decreaseItemQuantity = () => {
    const newQuantity = itemQuantity > 0 ? (itemQuantity - 1) : 0
    changeItemQuantity(id, newQuantity)
    setItemQuantity(newQuantity);
  }
  const deleteGood = () => {
    changeItemQuantity(id, 0)
  }

  return (
    <article key={id ** 2} className="goods-item">
      <div className="goods-item__content">
        <div className="goods-item__left">
          <div className="goods-item__left__image">
            <img src={image || ProductPlaceHolderImg} width="80" height="80" alt="Good item" />
          </div>
          <div className="goods-item__left__info">
            <h3>{name}</h3>
            <p>${(price / unitValue).toFixed(2)}/{unit}</p>
          </div>
        </div>
        <div className="goods-item__right">
          {/* <div className="goods-item__right__number-input">
            <button
              onClick={decreaseItemQuantity}
              type="button"
              className="goods-item__right__number-btn"
              disabled={itemQuantity === VALUES.MIN}
            >
              &#x2212;
            </button>
            <input
              type="number"
              min="1"
              max="999"
              value={itemQuantity}
              onChange={onItemQuantityChange}
              readOnly
            />
            <button
              onClick={increaseItemQuantity}
              type="button"
              className="goods-item__right__number-btn"
              disabled={itemQuantity === VALUES.MAX}
            >
              &#43;
            </button>
          </div> */}
          <div className="goods-item__right__number-input">
            <CardActions disableSpacing>
              <div className="product-actions">
                <div className="product-action-container">
                  <IconButton onClick={decreaseItemQuantity} className="product-action-button" >
                    <Remove color="secondary" className="product-action-button-icon" />
                  </IconButton>
                  <Typography className="product-count-typography" >{itemQuantity}</Typography>
                  <IconButton onClick={increaseItemQuantity} className="product-action-button" >
                    <Add color="secondary" className="product-action-button-icon" />
                  </IconButton>
                </div>
              </div>
            </CardActions>
          </div>
          <p className="goods-item__right__price">
            &#36;{(price * itemQuantity).toFixed(2)}
          </p>
        </div>
      </div>
      <button onClick={deleteGood} type="button" className="goods-item__remove-btn">
        <img src='/icons/cancel.svg' width="12" alt="Remove good from the list" />
        {/* <RemoveIcon /> */}
      </button>
    </article>
  );
};

GoodsCard.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
}

const mapDispatchToProps = dispatch => ({
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity)),
  openToast: (message) => dispatch(openToastState({ message, type: 'info' })),
})

export default connect(null, mapDispatchToProps)(GoodsCard);
