import {
  SET_BUILDINGS,
  SELECT_BUILDING,
} from './constants'

export const initState = {
  buildings: [],
  selectedBuilding: undefined,
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_BUILDINGS:
      return {
        ...state,
        buildings: payload,
      }

    case SELECT_BUILDING:
      return {
        ...state,
        selectedBuilding: payload,
      }

    default:
      return state
  }
}
