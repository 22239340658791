import React, { useRef } from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Product from '../Product';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack, ArrowForward } from '@material-ui/icons'

import { addGood, changeQuantity, refreshData as refreshCart } from 'reducers/cart/actions'
import { toggleWarning } from 'reducers/modals/actions'

import './_styles.scss'

const HorizontalProductList = ({
  products = [],
  onProductClick,
  addToCart,
  onAdd,
  onRemove,
  className,
}) => {
  const listRef = useRef(null)

  const onScroll = (position) => {
    const OFFSET = 500
    if (position === 'left') {
      listRef.current.scroll({
        left: listRef.current.scrollLeft - OFFSET,
        behavior: 'smooth'
      })
    } else {
      listRef.current.scroll({
        left: listRef.current.scrollLeft + OFFSET,
        behavior: 'smooth'
      })
    }
  }

  return (
    <Grid container spacing={1} className={`${className} horizontal-product-list bg-white mb-4`}>
      <div className="pagination pagination-prev">
        <IconButton className="pagination-icon" onClick={() => onScroll('left')}>
          <ArrowBack />
        </IconButton>
      </div>
      <GridList className='horizontal-product-list-grid-list' cols={2.5} ref={listRef}>
        {products.map((product, index) => (
          <GridListTile
            key={'rec-product-key-' + index}
            className="grid-products horizontal-product-list-grid-tile py-2 px-1"
          >
            <Product
              product={product}
              key={'rec-product-key-' + index}
              addToCart={addToCart}
              onAdd={onAdd}
              onRemove={onRemove}
              onClick={() => onProductClick(product)}
            />
          </GridListTile>
        ))}
      </GridList>
      <div className="pagination pagination-next">
        <IconButton className="pagination-icon" onClick={() => onScroll('right')}>
          <ArrowForward />
        </IconButton>
      </div>
    </Grid>
  );
}

const mapStateToProps = ({ cart }) => ({
  cart,
})

const mapDispatchToProps = dispatch => ({
  addItem: (item) => dispatch(addGood({ item })),
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity)),
  clearCart: () => dispatch(refreshCart()),
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: true, callback, message })),
})

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalProductList)