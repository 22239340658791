import {
  OPEN_TOAST,
  CLOSE_TOAST,
} from './constants'

export const openToast = ({ message, type }) => {
  return {
    type: OPEN_TOAST,
    payload: {
      message, type
    },
  }
}

export const closeToast = () => {
  return {
    type: CLOSE_TOAST,
  }
}
