import React, { Fragment } from "react";
import { Box } from "@material-ui/core";
import { format, isDate } from 'date-fns'
import BaseModal from 'components/common/modal'
import Button from 'components/common/button'
import "./_styles.scss";

import OrderSuccessImage from './images/order-success.png'

const OrderSuccessModal = ({
  open,
  handleClose,
  orderId,
  pickupTime,
}) => {
  let date
  let time
  if (isDate(pickupTime)) {
    date = format(pickupTime, 'MMM dd, yyyy')
    time = format(pickupTime, 'h:mm a')
  }
  return (
    <BaseModal
      open={open}
      onClose={handleClose}
    >
      <div className="paper --order">
        <div className="account__content">
          <Box textAlign="center" pt={8} pb={5}>
            <img
              alt="success"
              width="257"
              height="187"
              src={OrderSuccessImage}
            />
          </Box>
          <Box>
            <h2 className="account__title">Thank you!</h2>
            <p className="account__desc">
              Your Order Number is:
              <span className="account__bold"> {orderId}</span> <br />
              {pickupTime ? (
                <Fragment>
                  It will be ready For Pick Up On{" "}
                  <span className="account__bold"> {date}</span> <br /> at
                  <span className="account__bold"> {time} </span> Please save your
              receipt number <br /> when picking up
                </Fragment>
              ) : (
                  <Fragment>
                    We will notify you once your order is packed
                  </Fragment>
                )}
            </p>
          </Box>
          <Box textAlign="center" pt={2.5} width='50%' margin='0 auto'>
            <Button classes={'success no-bg'} onClick={handleClose}>
              OK
            </Button>
          </Box>
        </div>
      </div>
    </BaseModal>
  );
};

export default OrderSuccessModal;
