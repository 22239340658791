import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import './_styles.scss'

const Row = ({ title, tooltipIcon, tooltipTitle, value, className }) => {
  return (
    <div className={`cart-summary-block ${className || ''} cart-summary-block-tooltip`}>
      <Tooltip title={
        <React.Fragment>
          <Typography color="inherit">{tooltipTitle}</Typography>
        </React.Fragment>
      }>
        <span className='summary-row-title'>
          {title}
          &nbsp;
          {tooltipIcon}
        </span>
      </Tooltip>
      <span className='summary-row-amount'>{value}</span>
    </div>
  )
}

export default Row