import React from 'react'

import './styles.scss'

const HtmlElement = (textarea, props) => textarea ? <textarea {...props} /> : <input {...props} />
const InputStyle2 = ({ placeholder, type, onChange, rightIcon, textarea, pattern, ...rest }) => {
  const styles = {
    paddingRight: rightIcon ? '60px' : undefined
  }
  return (
    <div className={`input-style input-style-2 ${textarea ? 'textarea-style-2' : ''}`}>
      {rightIcon && <img src={rightIcon} alt='search' className='input-right-icon' />}
      {HtmlElement(textarea, {
        placeholder,
        onChange,
        type,
        style: styles,
        pattern: pattern || '^[a-zA-Z0-9!@#$&()\\-`.+,/\" ]*$',
        title: "Only alphabet/numberic and a few special characters is allowed",
        ...rest,
      })}
      {/* <input
        placeholder={placeholder}
        onChange={onChange}
        type={type}
        style={styles}
        {...rest}
      /> */}
    </div>
  )
}

export default InputStyle2