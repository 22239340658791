import request from './base'

export const createOrder = data => {
  return request({
    url: '/orders',
    method: 'POST',
    data,
  })
}

export const getOrders = (page = 0) => {
  return request({
    url: '/orders',
    params: {
      page
    },
  })
}

export const getOrderDetail = (orderId) => {
  return request({
    url: `/orders/${orderId}`
  })
}

export const authorizePayment = orderId => {
  return request({
    url: `/orders/${orderId}/authorize_payment`,
    method: 'POST',
  })
}

export const updateOrderItem = (orderId, orderItemId, data) => {
  return request({
    url: `/orders/${orderId}/order_items/${orderItemId}`,
    method: 'PUT',
    data,
  })
}

export const getOrderQuotation = ({ products, building, deliveryTime, promotionCode }) => {
  return request({
    url: `/orders/quotation`,
    method: 'POST',
    data: {
      products,
      building,
      deliveryTime,
      promotionCode,
    }
  })
}

export const verifyPayment = (orderId, data) => {
  return request({
    url: `/orders/${orderId}/verify_payment`,
    method: 'POST',
    data,
  })
}

export const checkCancellation = (orderId) => {
  return request({
    url: `/orders/${orderId}/check_cancellation`,
    method: 'POST',
  })
}


export const cancelOrder = (orderId) => {
  return request({
    url: `/orders/${orderId}/cancel`,
    method: 'POST',
  })
}
