import React, { useEffect } from 'react';
import NavigatorSearch from '../StoreName/components/navigator-search';

import './_styles.scss';

const ProductsSearch = ({
  search,
  value,
}) => {
  useEffect(() => {
    // const element = document.getElementsByTagName('main')[0]
    const onScolling = () => {
      const scrolled = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
      const productSearchElement = document.getElementById('products-search')
      if (!productSearchElement) return
      if (scrolled >= 210) {
        productSearchElement.classList.add('fixed')
      } else {
        productSearchElement.classList.remove('fixed')
      }
    }
    window.addEventListener('scroll', onScolling, false)
    return () => {
      window.removeEventListener('scroll', onScolling)
    }
  }, [])
  return (
    <div id='products-search'>
      <NavigatorSearch search={search} value={value} />
    </div>
  )
}

export default ProductsSearch