import React, { memo, useState } from 'react';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useStyles from './style';
import Select from '../core/Select';
import SelectMulti from '../core/SelectMulti';

import './_styles.scss'

const sortOptions = [
  {
    label: 'None',
    value: ''
  },
  {
    label: 'A-Z',
    value: 'name_1'
  },
  {
    label: 'Z-A',
    value: 'name_-1'
  }
]

const SelectedCategory = memo(({
  categories,
  category,
  subcategory,
  searchSelectedCates,
  setSearchSelectedCates,
  onPageChange,
  searchKeyword,
  search,
  hasNoProduct,
  currentSort,
  setSort,
}) => {
  const classes = useStyles();
  const [isCateFilterChanged, setIsCateFilterChanged] = useState(false)

  const handleSortBy = sort => {
    if (!sort) {
      onPageChange({
        page: 1,
      })
      setSort('')
    } else {
      const [sortBy, value] = sort.split('_')
      onPageChange({
        page: 1,
        sort: { [sortBy]: value }
      })
      setSort(sort)
    }
  }

  const handleCategorySeleted = categories => {
    setIsCateFilterChanged(true)
    setSearchSelectedCates(categories)
  }

  const onOpenCateFilter = () => {
    setIsCateFilterChanged(false)
  }

  const onCloseCateFilter = () => {
    if (isCateFilterChanged) {
      search(searchKeyword, searchSelectedCates)
    }
  }

  const getSearchKeywordTypo = () => {
    if (!hasNoProduct) {
      return <>Results for <b>"{searchKeyword}"</b>{category ? <> in <b>{category.label}</b></> : ''}</>
    }
    return <><b>"{searchKeyword}"</b> is not available{category ? <> in <b>{category.label}</b></> : ''}</>
  }
  return (
    <>
      {searchKeyword ? (
        <div className="product-list-head-search">
          <Typography variant="h4" gutterBottom className={`${classes.listHeader} text-underline-bold`}>
            {getSearchKeywordTypo()}
          </Typography>
          <div className={classes.filterSection}>
            <SelectMulti
              values={searchSelectedCates}
              name='Departments'
              handleChange={handleCategorySeleted}
              options={categories}
              onOpen={onOpenCateFilter}
              onClose={onCloseCateFilter}
            />
          </div>
        </div>
      ) : (
        <div className="category-breadcrumbs">
          {/* <Typography variant="h4" gutterBottom className={`${classes.listHeader} text-underline-bold`}>
            {category && category.label}
          </Typography> */}
          <Breadcrumbs
            separator={(
              <Typography variant="h5">
                »
              </Typography>
            )}
          >
            <Typography variant="h5" className='category-breadcrumbs-item'>
              {category && category.label}
            </Typography>
            {subcategory && (
              <Typography variant="h5" className='category-breadcrumbs-item'>
                {subcategory && subcategory.label}
              </Typography>
            )}
          </Breadcrumbs>
          <div className={classes.filterSection}>
            <Select
              name='Sort'
              value={currentSort}
              handleChange={handleSortBy}
              options={sortOptions}
            />
          </div>
        </div>
      )}
    </>
  );
})

const mapStateToProps = ({ category }) => ({
  categories: category.categories.map(c => ({ value: c._id, label: c.label })),
})

export default connect(mapStateToProps)(SelectedCategory)