import React from 'react'
import { connect } from 'react-redux'
import ResetPasswordForm from './ResetForm'

import { toggleWarning } from 'reducers/modals/actions'

import './_styles.scss'

const ResetPassword = ({ location, history, openWarningModal }) => {
  const onDone = () => {
    openWarningModal({
      callback: () => history.push('/'),
      message: 'Your password has been updated successfully.'
    })
  }
  return (
    <div className='reset-password'>
      <div className='reset-password-form'>
        <ResetPasswordForm location={location} onDone={onDone} />
      </div>
      <div className='reset-password-background' />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})

export default connect(null, mapDispatchToProps)(ResetPassword)
