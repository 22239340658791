import {
  OPEN_TOAST,
  CLOSE_TOAST,
} from './constants'

export const initState = {
  open: false,
  type: '',
  message: ''
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case OPEN_TOAST:
      return {
        ...state,
        open: true,
        type: payload.type,
        message: payload.message,
      }

    case CLOSE_TOAST:
      return {
        ...state,
        open: false,
        type: '',
        message: '',
      }

    default:
      return state
  }
}