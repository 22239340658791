import { fade, makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '100px',
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'flex-start',
    overflowX: 'auto',
    background: '#90909014'
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  rightMenuPanel: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none'
    },
    '&> *': {
      marginLeft: '40px',
      cursor: 'pointer',
      padding: 0,
      backgroundColor: 'transparent',
    }
  },
  extendedIconText: {
    fontSize: '15px',
    textTransform: 'capitalize',
    marginLeft: '8px',
  },
  storeButton: {
    width: '110px',
    borderRadius: '23px',
    boxShadow: 'none',
    background: '#e0e0e0',
    display: 'flex',
    alignItems: 'center',
    height: '45px',
    padding: '12px 23px',
    fontSize: '15px',
    fontWeight: 400,
    color: '#000',
    '&:hover': {
      background: '#5fa01b',
      transition: 'background 0.3s'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    }
  },
  nav: {
    color: '#000',
    '&:hover': {
      textDecoration: 'none',
      color: '#000',
    }
  },
  profileDropdown: {
    marginTop: '10px',
    '&::before': {
      // content: '""',
      // display: 'inline-block',
      // width: '10px',
      // height: '10px',
      // borderTop: '1px solid #000',
      // borderLeft: '1px solid #000',
      // position: 'absolute',
      // transform: 'rotate(45deg)',
      position: 'absolute',
      top: '-7px',
      left: '9px',
      display: 'inline-block',
      borderRight: '7px solid transparent',
      borderBottom: '7px solid #ccc',
      borderLeft: '7px solid transparent',
      borderBottomColor: 'rgba(0, 0, 0, 0.2)',
      content: '""',
      ':not(:last-child)': {
        borderBottom: '1px solid #000',
      }
    }
  },
  profileMenuItem: {
    width: '180px',
    fontSize: '13px',
    padding: '5px 18px',
  },
  profileMenuItemLastChild: {
    borderBottom: 'none',
  },
  profileMenuItemInner: {
    padding: '11px 0',
    borderBottom: '1px solid #F1F1F3',
    width: '100%',
    textAlign: 'center',
  },
  profileMenuItemInnerLastChild: {
    borderBottom: 'none',
  }
}));