import React from 'react';
import {
  Select,
  Typography,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {

  },
  select: {
    '&:focus': {
      borderRadius: '5px',
      backgroundColor: '#fff',
    },
    border: 'solid 1px #D8D8D8',
    borderRadius: '5px',
    padding: '10px 30px 10px 20px',
    minWidth: '50px',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontSize: '13px',
    padding: '5px 20px',
    fontWeight: 600,
  },
  option: {
    padding: '5px 10px',
    cursor: 'pointer',
  }
}));

export default function (props) {
  const { name, value, placeHolder, label, options = [], handleChange } = props;
  const classes = useStyles();
  const onChange = (e) => {
    if (handleChange) {
      handleChange(e.target.value)
    }
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.name}>
        {name}
      </Typography>
      <Select
        classes={{
          root: classes.select,
        }}
        displayEmpty
        disableUnderline={true}
        value={value}
        name="select"
        onChange={onChange}
        inputProps={{ 'aria-label': label }}
      >

        {/* <option value="" disabled>
          {placeHolder}
        </option> */}
        {
          options.map((option, index) => <option
            className={classes.option}
            value={option.value}
            key={option.value + "-" + index}
          >{option.label}</option>)
        }
      </Select>
    </div>
  )
}