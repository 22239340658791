import React from 'react'

function NotFound() {
  return (
    <div className="row align-items-center h-100">
      <div className="mx-auto text-center not-found">
        <span>
          <span className="display-4">Page Not Found</span>
          {/* <p>{'(╯°□°)╯︵ ┻━┻'}</p> */}
        </span>
      </div>
    </div>
  )
}

export default NotFound
