import React from 'react';
import { connect } from 'react-redux'

import InputStyle2 from 'components/common/inputs/style2'
import BaseModal from 'components/common/modal'
import Button from 'components/common/button'

import './_styles.scss'

// redux
import { setUser } from 'reducers/user/actions'
import { toggleWarning } from 'reducers/modals/actions'

// network
import { forgotPassword } from 'services/user'

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }
  emailHandler = (event) => {
    this.setState({ email: event.target.value });
  }

  onSubmit = async () => {
    const data = await forgotPassword(this.state.email)
    if (data.result !== 'success') {
      return this.setState({
        error: data.err
      })
    }
    const { handleClose } = this.props
    // dispatch
    handleClose()
    this.props.openWarningModal({
      callback: () => { },
      message: 'An email has been sent to your email address. Follow the instruction in the email to reset your password.'
    })
  }

  toLogin = e => {
    e.preventDefault()
    this.props.toLogin()
  }

  render() {
    const {
      open,
      handleClose,
    } = this.props
    return (
      <BaseModal
        open={open}
        onClose={handleClose}
        className='login-modal'
      >
        <div role="dialog" aria-labelledby="exampleModalCenterTitle" className='login-container'>
          <div className="login_header text-center">

            <h4 className="mb-3">Forgot Password</h4>
            <p>We will send you an email to reset the password.</p>
          </div>
          <div className="modal-body">
            <div className="form mt-3">
              <div>
                <div className="input-group form-group position-relative">
                  <InputStyle2 type="email" id="emails" onChange={this.emailHandler} name="emails" placeholder="E-mail" />
                </div>
                {this.state.error && (
                  <p style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</p>
                )}
                <div className="form-group submit_button mt-2">
                  <Button
                    classes='success'
                    style={{
                      height: '56px',
                      fontSize: '13px',
                      fontWeight: 600,
                    }}
                    onClick={this.onSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
              <div className="text-center mt-4">
                <span>Remember the password?</span> <div className="a-tag signup_btn black_color" onClick={this.toLogin}>Login</div>
              </div>
            </div>

          </div>
        </div>
      </BaseModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setUserAfterLogin: user => dispatch(setUser(user)),
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})

export default connect(null, mapDispatchToProps)(ForgotPasswordModal);
