/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { getMappedOrderStatus, orderStatuses } from 'utils/helper'

const useStyles = makeStyles(() => ({
    statusBadge: {
        display: 'inline-block',
        color: '#ffffff',
        padding: '3px 15px',
        borderRadius: '10px',
        background: '#ddd',
        width: '6.5rem',
        textAlign: 'center',
        fontSize: '12px',
        fontWeight: 600,
        textTransform: 'capitalize',
        backgroundColor: '#47B853',
    },
    // statusPaid: {
    //     backgroundColor: '#5fa01b',
    // },
    statusPending: {
        backgroundColor: '#feb42c',
    },
    statusOutOfStock: {
        backgroundColor: '#ff0039',
    },
    statusPacked: {
        backgroundColor: '#2196f3',
    }
}));

const StatusBadge = ({ status, style }) => {
    const badgeStatus = getMappedOrderStatus(status)
    const classes = useStyles();
    const className = cx(classes.statusBadge, {
        [classes.statusPending]: badgeStatus === orderStatuses.PROCESSING,
        // [classes.statusPacked]: ['PACKED'].includes(badgeStatus),
        // [classes.statusPaid]: content === '',
        [classes.statusOutOfStock]: badgeStatus === orderStatuses.CANCELLED,
    });
    return (
        <span
            className={className}
            color="secondary"
            style={style}
        >
            {badgeStatus}
        </span>
    );
};

export default StatusBadge;
