import React, { useState } from 'react'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import SummaryRow from '../summary-row'
import SummaryRowTooltip from '../summary-row/summary-row-tooltip'
import ProgressButton from '../../../../common/button/ProgressButton'
import InputGroupButton from 'components/common/input-group-button'
import './_styles.scss'

const CartSummary = ({
  subtotal,
  tax, total,
  shippingFee,
  showPromotion,
  promotionCode,
  promotionAmount,
  onPromotionCodeChange,
  onApplyPromotion,
  itemCount,
  enableSubmit,
  submitting,
  existingOrder,
  submitError,
}) => {
  const [applyingPromotion, setApplyingPromotion] = useState(false)
  const applyPromotion = async () => {
    setApplyingPromotion(true)
    await onApplyPromotion()
    setApplyingPromotion(false)
  }
  return (
    <div className='summary'>
      <h2>Cart Summary</h2>
      <SummaryRow title={`Subtotal (${itemCount} items)`} value={`$${subtotal.toFixed(2)}`} />
      <SummaryRowTooltip
        title='Delivery fee'
        value={`$${shippingFee}`}
        tooltipIcon={<InfoOutlinedIcon />}
        tooltipTitle="$5.99 delivery fee on orders over $30 and $7.49 on orders under $30."
      />
      <SummaryRow title='Taxes' value={`$${tax.toFixed(2)}`} />
      {showPromotion && (
        <InputGroupButton
          placeholder='Promo Code'
          required={false}
          value={promotionCode}
          onChange={onPromotionCodeChange}
          tabindex="10"
          onButtonClick={applyPromotion}
          buttonText="Apply"
          loading={applyingPromotion}
        />
      )}
      {Boolean(promotionAmount) && (
        <SummaryRow title='Discount' value={`-$${promotionAmount.toFixed(2)}`} />
      )}
      <div className='cart-summary-block-total'>
        <span className='summary-row-title'>Total</span>
        <span className='summary-row-amount'>${total.toFixed(2)}</span>
      </div>
      <ProgressButton
        classes='success'
        form='checkout-form'
        type='submit'
        disabled={submitting || !enableSubmit}
        submitting={submitting}
      >
        {existingOrder ? 'ADD TO EXISTING ORDER' : 'CHECKOUT'}
      </ProgressButton>
      {submitError && (
        <p style={{ color: 'red', textAlign: 'center' }}>{submitError}</p>
      )}
    </div>
  )
}

export default CartSummary
