import React, { memo } from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Product from './Product';
import Pagination from '../core/Pagination';
import { Skeleton } from '@material-ui/lab'
import { toggleWarning } from 'reducers/modals/actions'

import './_styles.scss'

const PAGE_LIMIT = 50

const ProductList = memo(({
  productsData: {
    products,
    count,
  },
  currentPage,
  onPageChange,
  onProductClick,
  addToCart,
  onAdd,
  onRemove,
  currentSort
}) => {
  const handlePageChange = (page) => {
    if (page === currentPage) return
    const payload = { page }
    if (currentSort) {
      const [sortBy, value] = currentSort.split('_')
      payload.sort = { [sortBy]: value }
    }
    onPageChange(payload)
  }

  const getLoader = () => (
    <>
      {Array(20).fill(
        <div className='products-loading'>
          <Skeleton variant="rect" animation="wave" width={190} height={140} />
          <Skeleton variant="text" animation="wave" height={13} style={{ marginBottom: '3px' }} />
          <Skeleton variant="text" animation="wave" height={13} width="60%" style={{ marginBottom: '3px' }} />
          <Skeleton variant="text" animation="wave" height={24} width="50%" style={{ margin: '0 auto' }} />
          <Skeleton variant="rect" animation="wave" height={40} style={{ borderRadius: '23px' }} />
        </div>
      )}
    </>
  )
  const showProducts = () => {
    if (products.length > 0) return products.map((product, index) =>
    (<Grid item key={'product-key-' + product._id} className="grid-products py-2 px-1">
      <Product
        product={product}
        key={'product-key-' + product._id}
        addToCart={addToCart}
        onAdd={onAdd}
        onRemove={onRemove}
        onClick={() => onProductClick(product)}
      />
    </Grid>))
  }
  return (
    <>
      <Grid container spacing={1} className='bg-white'>
        {products ? showProducts() : getLoader()}
      </Grid>
      <Pagination
        count={Math.ceil(count / PAGE_LIMIT)}
        page={currentPage}
        onChange={handlePageChange}
      />
    </>
  );
}, (prevProps, nextProps) => {
  return prevProps.productsData === nextProps.productsData && prevProps.cart === nextProps.cart
})

const mapStateToProps = ({ category }) => ({
  categories: category.categories.map(c => ({ value: c._id, label: c.label })),
})

const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: true, callback, message })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)