import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Fab,
  IconButton,
} from '@material-ui/core'
import {
  Add,
  Remove,
} from '@material-ui/icons'
import Button from 'components/common/button'
import useStyles from './style';
import { ReactComponent as ShoppingCartIcon } from '../icons/shopping-cart-1.svg'

// actions
import { addGood, changeQuantity } from 'reducers/cart/actions'

const ProductPlaceHolderImg = '/images/product-placeholder.png'

const ProductModal = ({
  cart,
  product,
  addToCart,
  onRemove,
  onAdd,
}) => {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(product.quantity || 0);

  useEffect(() => {
    const productFromCart = cart[product._id]
    product.quantity = productFromCart?.quantity || 0
    setQuantity(product.quantity || 0)
  }, [product, cart])

  return (
    <Box>
      <Card className={`${classes.root} product-card`}>
        <CardMedia
          component="img"
          alt="Product"
          // height="140"
          image={product.image || ProductPlaceHolderImg}
          title={product.name}
        />
        <CardContent className={classes.cardTitleContainer}>
          <Typography variant="subtitle2" className='product-card-title-text'>
            {product.name}
          </Typography>
        </CardContent>
        <CardContent className='product-card-meta'>
          <Typography variant="body1" className='product-card-meta-size'>
            {product.pricePerUnit && 'pkg '}{product.size}
          </Typography>
          <Typography variant="body1" className='product-card-meta-price'>
            ${product.price.toFixed(2)} {product.pricePerUnit && `/ ${product.unit}`}
          </Typography>
        </CardContent>
        {quantity <= 0 && <CardActions disableSpacing>
          <Fab
            // variant="extended"
            size="medium"
            color="green"
            aria-label="add"
            className="btn-add-to-cart product-actions"
            onClick={() => addToCart(product)}
            style={{
              padding: 0,
            }}
            classes={{
              label: classes.storeButtonLabel,
            }}
          >
            {product.inventory === 0 ? (<Button classes="product-action-extended-icon-text" disabled={true}>
              Out of stock
            </Button>) : (<Button classes="product-action-extended-icon-text">
              Add to cart
              </Button>)}
          </Fab>
        </CardActions>}
        {quantity > 0 && <CardActions disableSpacing>
          <div className="product-actions">
            <div className="product-action-left-icon">
              <ShoppingCartIcon style={{
                fill: '#5FA01B',
              }} />
            </div>
            <div className="product-action-container">
              <IconButton onClick={() => onRemove(product)} className="product-action-button" >
                <Remove color="secondary" className="product-action-button-icon" />
              </IconButton>
              <Typography className="product-count-typography" >{quantity}</Typography>
              <IconButton onClick={() => onAdd(product)} className="product-action-button" >
                <Add color="secondary" className="product-action-button-icon" />
              </IconButton>
            </div>
          </div>
        </CardActions>}
      </Card>
    </Box>
  );
}

const mapStateToProps = ({ cart }) => ({
  cart: cart.goods,
})
const mapDispatchToProps = dispatch => ({
  addItem: (item) => dispatch(addGood({ item })),
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal)
