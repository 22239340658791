import { SET_CATEGORIES } from './constants'

export const initState = {
  categories: [],
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case SET_CATEGORIES:
      return { ...state, categories: payload }

    default:
      return state
  }
}