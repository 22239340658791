import React from 'react'
import { Modal } from '@material-ui/core'

import './styles.scss'

import CloseIcon from './icons/close.svg'

const BaseModal = ({ open, onClose, children, styles, className }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={`modal-layout ${className}`}
    >
      <div className='modal-container' style={styles}>
        <span onClick={onClose} className='modal-close'>
          <img src={CloseIcon} alt='close modal' />
        </span>
        {children}
      </div>
    </Modal>
  )
}

export default BaseModal;
