/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo, useCallback } from 'react';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import SideNavBar from './sidebar/SideNavBar';
import ProductLists from './products/ProductLists';
import StoreName from './StoreName'
import Modal from 'components/common/modal'
import ProductDetailModal from './products/ProductModal'
import RecommendedProducts from './products/RecommendedProducts';
import SelectedCategory from './selectedCategory';
import ProductsSearch from './productsSearch';

import { getProducts, getRecommendedProducts } from 'services/products'

import {
  togglePickupCalendar as togglePickupCalendarState,
} from 'reducers/modals/actions'
import { setCategories } from 'reducers/category/actions'
import { openToast as openToastState } from 'reducers/toast/actions'
import { addGood, changeQuantity } from 'reducers/cart/actions'
import { shuffleArray } from 'utils/helper'

import './_styles.scss'

const Store = memo(({
  categoriesState,
  building,
  addItem,
  openToast,
  changeItemQuantity,
}) => {
  const history = useHistory();
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const [categoryData, setCategoryData] = useState({
    cates: [],
    chosenCate: undefined,
    selectedCateIndex: 0,
    chosenSubcate: undefined,
    selectedSubcateIndex: -1,
    notSelectCate: false,
  })

  const [products, setProducts] = useState([])
  const [productCount, setProductCount] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState()
  const [detailModalOpen, setDetailModalOpen] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchSelectedCates, setSearchSelectedCates] = useState([])
  const [lastLoadProductsPayload, setLastLoadProductsPayload] = useState({})

  const [currentSort, setSort] = useState('')

  const loadProducts = useCallback(async ({
    cate,
    subcate,
    page = 1,
    sort,
    name,
    optionalFilter,
    withCount,
  }) => {
    setCurrentPage(page)
    // fetch products
    let filter = {}
    if (cate) {
      filter.category = cate._id
    }
    if (subcate) {
      filter.subcategory = subcate._id
      delete filter.category // we dont need category if we have subcategory
    }
    if (name) {
      filter.name = name
    }
    if (optionalFilter) {
      filter = {
        ...filter,
        ...optionalFilter,
      }
    }
    const res = await getProducts(filter, page - 1, sort, withCount)
    if (res.result !== 'success') return
    const { products, count } = res.data
    setProducts(products)
    if (!isNaN(count)) setProductCount(count)
  }, [])
  
  const initCategories = useCallback(async () => {
    if (categoriesState.length === 0) return
    if (new URLSearchParams(history.location.search).get('search')) {
      loadInitProductsWithSearch()
    } else {
      loadInitProductsWithoutSearch()
    }
    loadRecommendedProducts()
    return categoriesState
  }, [categoriesState])
  const loadInitProductsWithSearch = () => {
    const query = new URLSearchParams(history.location.search);
    setSearchKeyword(query.get('search'))
    const searchSelectedCates = query.get('categories') ? query.get('categories').split(',') : [];
    setSearchSelectedCates(searchSelectedCates)
    setCategoryData({
      ...categoryData,
      cates: categoriesState,
      selectedCateIndex: -1,
      chosenCate: undefined,
      selectedSubcateIndex: -1,
      chosenSubcate: undefined,
      notSelectCate: true,
    })
    const payload = {
      page: query.get('page') || 1,
      name: query.get('search'),
      optionalFilter: {
        category: searchSelectedCates,
      },
      withCount: true,
    }
    loadProducts(payload)
    setLastLoadProductsPayload(payload)
  }
  const loadInitProductsWithoutSearch = () => {
    const query = new URLSearchParams(history.location.search);
    let chosenCategoryIndex = -1
    let chosenSubcategoryIndex = -1
    if (query.get('category')) {
      chosenCategoryIndex = categoriesState.findIndex(c => c._id === query.get('category'))
    }
    if (query.get('subcategory') && chosenCategoryIndex !== -1) {
      chosenSubcategoryIndex = categoriesState[chosenCategoryIndex].subcategories.findIndex(s => s._id === query.get('subcategory'))
    }
    const newCategoryData = {
      ...categoryData,
      cates: categoriesState,
      chosenCate: chosenCategoryIndex !== -1 ? categoriesState[chosenCategoryIndex] : categoriesState[0],
      selectedCateIndex: chosenCategoryIndex !== -1 ? chosenCategoryIndex : 0,
      chosenSubcate: chosenSubcategoryIndex !== -1 && categoriesState[chosenCategoryIndex].subcategories[chosenSubcategoryIndex],
      selectedSubcateIndex: chosenSubcategoryIndex,
      notSelectCate: false,
    }
    setCategoryData(newCategoryData)

    const payload = {
      page: query.get('page') || 1,
      cate: newCategoryData.chosenCate,
      subcate: newCategoryData.chosenSubcate,
      withCount: true,
    }
    loadProducts(payload)
    setLastLoadProductsPayload(payload)
  }

  const init = useCallback(async () => {
    initCategories()
  }, [initCategories])
  useEffect(() => {
    init()
  }, [init])

  const loadRecommendedProducts = async () => {
    const res = await getRecommendedProducts()
    if (res.result !== 'success') return
    const { products } = res.data
    const shuffledProducts = shuffleArray(products).map(recommendedProduct => ({
      ...recommendedProduct,
      _id: recommendedProduct.product,
    }))
    setRecommendedProducts(shuffledProducts)
  }

  const onSelectSubcate = async index => {
    const {
      cates,
      selectedCateIndex,
    } = categoryData
    setProducts([])
    setSearchKeyword('')
    const cate = cates[selectedCateIndex]
    if (!cate) return
    const subcate = cates[selectedCateIndex].subcategories[index]
    if (!subcate) return
    setCategoryData({
      ...categoryData,
      chosenSubcate: subcate,
      selectedSubcateIndex: index,
      notSelectCate: false,
    })
    updateQueryParams({
      search: '',
      category: cate._id,
      subcategory: subcate._id,
      categories: '',
      page: '',
    })

    const payload = { subcate, name: searchKeyword, withCount: true }
    loadProducts(payload)
    setLastLoadProductsPayload(payload)
  }

  const onSelectCate = async index => {
    const cate = categoryData.cates[index]
    if (!cate) return
    setSearchKeyword('')
    setCategoryData({
      ...categoryData,
      selectedCateIndex: index,
      chosenCate: cate,
      chosenSubcate: undefined,
      notSelectCate: false,
    })
    updateQueryParams({
      search: '',
      category: cate._id,
      subcategory: '',
      categories: '',
      page: '',
    })

    const payload = { cate, subcate: null, name: searchKeyword, withCount: true }
    if (currentSort) {
      const [sortBy, value] = currentSort.split('_')
      payload.sort = { [sortBy]: value }
    }
    loadProducts(payload)
    setLastLoadProductsPayload(payload)
  }

  const onPageChange = async ({ page = 1, sort }) => {
    if (page > 1) {
      updateQueryParams({
        page,
      })
    }
    const payload = {
      ...lastLoadProductsPayload,
      page,
      sort,
      withCount: undefined,
    }
    loadProducts(payload)
    setLastLoadProductsPayload(payload)
  }

  const search = async (keyword, filterCategories) => {
    setSearchKeyword(keyword)
    const cateData = keyword ? {
      ...categoryData,
      selectedCateIndex: -1,
      chosenCate: undefined,
      selectedSubcateIndex: -1,
      chosenSubcate: undefined,
      notSelectCate: true,
    } : {
      ...categoryData,
      selectedCateIndex: categoryData.selectedCateIndex || 0,
      chosenCate: categoryData.chosenCate || categoriesState[0],
      notSelectCate: false,
    }
    setCategoryData(cateData)
    const payload = {
      page: 1,
      name: keyword,
      cate: cateData.chosenCate,
      subcate: cateData.chosenSubcate,
    }
    if (filterCategories) {
      payload.optionalFilter = {
        category: filterCategories,
      }
    }
    updateQueryParams({
      search: keyword,
      category: payload.cate?._id,
      subcategory: payload.subcate?._id,
      categories: keyword ? payload.optionalFilter?.category : '',
      page: '',
    })
    loadProducts(payload)
    setLastLoadProductsPayload(payload)
  }

  const addToCart = async (product) => {
    addItem(product)
  }
  const onAdd = (product) => {
    if (product.inventory >= 0 && product.inventory <= product.quantity) {
      // not check if inventory is -1 or undefined
      return openToast(`Only ${product.inventory} available`)
    }
    const newQuantity = product.quantity + 1
    changeItemQuantity(product._id, newQuantity)
  }
  const onRemove = (product) => {
    const newQuantity = product.quantity > 0 ? (product.quantity - 1) : 0
    changeItemQuantity(product._id, newQuantity)
  }

  const updateQueryParams = (params) => {
    const query = new URLSearchParams(history.location.search);
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        query.set(key, value)
      } else {
        query.delete(key)
      }
    })
    history.replace({ search: query.toString() })
  }

  const storeNameSearch = (data) => {
    setSearchSelectedCates([])
    search(data)
  }

  const openProductDetail = product => {
    setSelectedProduct(product)
    setDetailModalOpen(true)
  }

  return (
    <Container maxWidth={false} id='store-container' className='main-body'>
      <main>
        <StoreName
          search={storeNameSearch}
          keyword={searchKeyword}
          buildingName={building?.name}
        />
        <Grid
          container
          justify="center"
          className="store-search"
        >
          <ProductsSearch search={storeNameSearch} value={searchKeyword} />
        </Grid>
        <Grid container spacing={4} className="store-main-container">
          <Grid container>
            <RecommendedProducts 
              products={recommendedProducts}
              addToCart={addToCart}
              onAdd={onAdd}
              onRemove={onRemove}
              onSelectProduct={openProductDetail}
            />
          </Grid>
          <Grid container className='search-by-departments'>
            <div className='search-by-departments-container'>
              <h5>
                Search by Departments
              </h5>
            </div>
          </Grid>
          <Grid item xs={12} lg={2} className="left-container">
            <h4 className="mt-2">Departments</h4>
            <SideNavBar
              categories={categoryData.cates}
              onChooseCate={onSelectCate}
              onChooseSubcate={onSelectSubcate}
              selectedIndex={categoryData.selectedCateIndex}
              selectedSubcateIndex={categoryData.selectedSubcateIndex}
              notSelect={categoryData.notSelectCate}
            />
          </Grid>
          <Grid item xs={12} lg={10} className="right-container" >
            <SelectedCategory
              category={categoryData.chosenCate}
              subcategory={categoryData.chosenSubcate}
              searchSelectedCates={searchSelectedCates}
              setSearchSelectedCates={setSearchSelectedCates}
              onPageChange={onPageChange}
              searchKeyword={searchKeyword}
              search={search}
              hasNoProduct={!products || products.length === 0}
              currentSort={currentSort}
              setSort={setSort}
            />
            <ProductLists
              productsData={{ products, count: productCount }}
              onSelectProduct={openProductDetail}
              category={!categoryData.notSelectCate ? (categoryData.chosenSubcate || categoryData.chosenCate) : undefined}
              currentPage={currentPage}
              onPageChange={onPageChange}
              searchKeyword={searchKeyword}
              search={search}
              searchSelectedCates={searchSelectedCates}
              setSearchSelectedCates={setSearchSelectedCates}
              currentSort={currentSort}
            />
          </Grid>
        </Grid>
        <Modal
          open={detailModalOpen}
          onClose={() => setDetailModalOpen(false)}
        >
          <ProductDetailModal
            product={selectedProduct}
            addToCart={addToCart}
            onAdd={onAdd}
            onRemove={onRemove}
          />
        </Modal>
      </main>
    </Container>
  );
})

const mapStateToProps = ({ category, user }, ownProps) => {
  return {
    categoriesState: ownProps.categoriesState || category.categories,
    subcategoriesState: ownProps.subcategoriesState || category.subcategories,
    building: ownProps.building || user.building,
  }
}
const mapDispatchToProps = dispatch => ({
  togglePickupCalendar: open => dispatch(togglePickupCalendarState({ open })),
  setCategoriesState: categories => dispatch(setCategories(categories)),
  addItem: (item) => dispatch(addGood({ item })),
  openToast: (message) => dispatch(openToastState({ message, type: 'info' })),
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Store)
