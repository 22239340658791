import { addMinutes, addDays } from "date-fns"
import {
  SET_STORE,
  SET_STATE_INIT,
  SET_SEARCH_KEYWORD,
  CLEAR_STORE,
} from './constants';
import {
  saveStore,
} from './methods'

const initialState = {
  selectedStore: {
    _id: undefined,
    tax: undefined,
    shippingFee: undefined,
    pickupFee: undefined,
    name: undefined,
    image: undefined,
    type: undefined,
    address: undefined,
    zipCode: undefined,
    businessUserId: undefined,
    openTime: {
      mon: {
        from: '',
        to: '',
      },
      tue: {
        from: '',
        to: '',
      },
      wed: {
        from: '',
        to: '',
      },
      thu: {
        from: '',
        to: '',
      },
      fri: {
        from: '',
        to: '',
      },
      sat: {
        from: '',
        to: '',
      },
      sun: {
        from: '',
        to: '',
      },
    },
    minPackingTime: 0,
    fixedPickupFee: 0,
    percentagePickupFee: 0,
  },
  pickupTime: {
    from: addDays(new Date(), 7),
    to: addMinutes(addDays(new Date(), 7), 30),
  },
  searchKeyword: '',
};

const storeReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_STORE: {
      const newStore = {
        ...state,
        selectedStore: {
          ...action.payload,
          openTime: action.payload.openTime || state.selectedStore.openTime
        }
      }
      saveStore(newStore)
      return newStore
    }

    case SET_STATE_INIT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case SET_SEARCH_KEYWORD: {
      return {
        ...state,
        searchKeyword: action.payload,
      }
    }

    case CLEAR_STORE:
      localStorage.clear('store')
      return { ...initialState }

    default: {
      return state;
    }
  }
};

export default storeReducer;
