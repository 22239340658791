import { SET_USER, CLEAR_USER } from './constants'

export const setUser = ({
  _id,
  firstName,
  lastName,
  phone,
  zipCode,
  email,
  token,
  image,
  building,
  buildingUnitNo,
}) => {
  return {
    type: SET_USER,
    payload: {
      _id,
      firstName,
      lastName,
      phone,
      zipCode,
      email,
      token,
      image,
      building,
      buildingUnitNo,
    }
  }
}

export const logout = () => {
  return {
    type: CLEAR_USER,
  }
}
