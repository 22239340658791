import {
  SET_STATES,
  SET_PRODUCT_CONSTANTS,
  SET_ORDER_CONSTANTS,
} from './constants'

export const setUsStates = (payload) => {
  return {
    type: SET_STATES,
    payload,
  }
}

export const setProductConstants = (payload) => {
  return {
    type: SET_PRODUCT_CONSTANTS,
    payload,
  }
}


export const setOrderConstants = (payload) => {
  return {
    type: SET_ORDER_CONSTANTS,
    payload,
  }
}

