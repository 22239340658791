import React from 'react'

import './styles.scss'

const HowItWorkCard = ({ image, title, content }) => {
  return (
    <div className="how-it-works-card">
      <img src={image} alt="how it work" />
      <h4>{title}</h4>
      <p>{content}</p>
    </div>
  )
}

export default HowItWorkCard