import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import './_Account.scss';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Button from 'components/common/button';
import { openToast as openToastState } from 'reducers/toast/actions'
import { toggleWarning } from 'reducers/modals/actions'

import { upload } from 'services/file'
import {
  setAvatar,
  updateProfile as updateProfileService,
  deleteAccount,
} from 'services/user'

import { 
  setUser as setUserAction,
  logout,
} from 'reducers/user/actions'

import { reformatPhone } from 'utils/helper'

const Account = ({
  user,
  buildings,
  dispatch,
  openWarningModal,
}) => {
  const history = useHistory()
  const [croppedImage, setCroppedImage] = useState({
    blob: '',
    base64: '',
  })
  const [submitError, setSubmitError] = useState('')

  const profileImgRef = useRef(null)

  useEffect(() => {
    if (user) {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('phone', reformatPhone(user.phone))
      const buildingFound = buildings.find(building => building._id === user.building)
      if (buildingFound) {
        setValue('building', buildingFound._id)
      }
      setValue('buildingUnitNo', user.buildingUnitNo)
    }
  }, [user, buildings])

  const onCropped = async ({ blob, base64 }) => {
    if (!base64) return setSubmitError('Not uploaded yet')
    // temporarily render img
    setCroppedImage({
      blob, base64
    })
    // upload
    const uploadImgRes = await upload(base64)
    if (uploadImgRes.result !== 'success') {
      return setSubmitError(uploadImgRes.err)
    }
    let savedUser
    try {
      savedUser = (await setAvatar(user._id, uploadImgRes.file._id)).data
    } catch (err) {
      return setSubmitError(err.response.data.err)
    }
    dispatch(setUserAction({
      ...user,
      ...savedUser,
    }))
    setCroppedImage({
      blob: '',
      base64: ''
    })
  }

  const { register, watch, setValue, handleSubmit, errors } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
    }
  })

  const updateProfile = async (event) => {
    event.preventDefault()
    setSubmitError()
    let res
    try {
      res = (await updateProfileService(user._id, {
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        phone: watch('phone').replace(/\D/g, ''),
        buildingUnitNo: watch('buildingUnitNo'),
        building: watch('building'),
      }))
    } catch (err) {
      return console.error(err)
    }
    if (res.result !== 'success') {
      return setSubmitError(res.err)
    }
    const updatedUser = res.data
    dispatch(openToastState({
      message: 'Profile updated!',
      type: 'success',
    }))
    dispatch(setUserAction(updatedUser))
  }

  const onPhoneChange = e => {
    e.target.value = reformatPhone(e.target.value)
  }

  const onDeleteAccount = (event) => {
    event.preventDefault()
    const callback = async () => {
      await deleteAccount()
      dispatch(logout())
      history.push('/')
    }
    dispatch(toggleWarning({
      open: true,
      withCancel: true,
      callback,
      message: 'Your account will be deleted from Cart & Order, all associated data will be deleted as well. Are you sure to delete your account?'
    }))
  }
  
  const back = () => {
    history.push(`/store`)
  }

  return (
    <div className="main-page-wrapper px-lg-40 profile-page">
      <Container fluid>
        <div className="page-title d-flex py-3">My Account</div>
        <div className="my-profile-container">
          <div className="my-profile-inner">
            <Row>
              <Col xs="12">
                <div className="myprofile-frm-right px-3 px-xl-0">
                  <Form>
                  {/* <Form onSubmit={handleSubmit(updateProfile)}> */}
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        First Name
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          placeholder="First name"
                          ref={register}
                          name='firstName'
                          defaultValue={watch('firstName')}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Last Name
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          placeholder="Last name"
                          ref={register}
                          name='lastName'
                          defaultValue={watch('lastName')}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Email
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          defaultValue={user.email}
                          disabled
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Phone Number
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          error={errors.phone?.message}
                          placeholder="Phone Number"
                          ref={register({
                            pattern: {
                              value: "^D*(d{3})D*(d{3})D*(d{4})",
                              message: 'Invalid phone format'
                            },
                            required: true,
                          })}
                          name='phone'
                          pattern="^\D*(\d{3})\D*(\d{3})\D*(\d{4})"
                          required={true}
                          rules={{
                            pattern: {
                              value: "^D*(d{3})D*(d{3})D*(d{4})",
                              message: 'Invalid phone format'
                            },
                            required: true,
                          }}
                          defaultValue={watch('phone')}
                          onChange={onPhoneChange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Building
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          as="select"
                          error={errors.building?.message}
                          placeholder="Building"
                          ref={register()}
                          name='building'
                          required={true}
                          value={watch('building')}
                        >
                          {buildings.map((building, i) => (
                            <option
                              value={building._id}
                              key={i}
                            >
                              {building.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm={3}>
                        Building Unit Number
                      </Form.Label>
                      <Col sm={9}>
                        <Form.Control
                          type="text"
                          error={errors.buildingUnitNo?.message}
                          placeholder="Building Unit Number"
                          ref={register()}
                          name='buildingUnitNo'
                          defaultValue={watch('buildingUnitNo')}
                        />
                      </Col>
                    </Form.Group>
                    <div className="actions">
                      <div className="actions-delete">
                        <Button
                          className="button md error"
                          onClick={onDeleteAccount}
                        >
                          Delete Account
                        </Button>
                      </div>
                      <div className="actions-update">
                        <Button
                          className="button md no-bg"
                          onClick={back}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="button md success"
                          onClick={updateProfile}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </div>
                    {submitError && (
                      <p style={{ color: 'red', textAlign: 'center' }}>{submitError}</p>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = ({ user, buildings }) => ({
  user,
  buildings: buildings.buildings,
})

export default connect(mapStateToProps)(Account)
