import {
  SET_BUILDINGS,
  SELECT_BUILDING,
} from './constants'

export const setBuildings = (payload) => {
  return {
    type: SET_BUILDINGS,
    payload,
  }
}

export const selectBuilding = (payload) => {
  return {
    type: SELECT_BUILDING,
    payload,
  }
}
