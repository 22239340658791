import {
  ADD_GOOD,
  ADD_GOODS,
  CHANGE_QUANTITY,
  REMOVE_GOOD,
  REFRESH_DATA,
  CLEAR_CART,
  CLEAR_CART_ALL,
  SET_PICKUP_DATE,
  SET_PICKUP_TIME,
  SET_STATE_INIT,
  SET_DELIVERY_TIME,
} from './constants';
import { removeArrayItem } from 'utils/helper';
import {
  addItemToCart,
  changeItemQuantity,
  getPickupDate,
  getPickupTime,
  saveCart,
} from './methods'

const initialState = {
  goods: {}, // easily manage cart if we use object instead of array
  pickupTimeStores: {}, // pickup time for many stores
  pickupTime: {
    // from: addDays(new Date(), 7),
    // to: addMinutes(addDays(new Date(), 7), 30),
    from: '',
    to: '',
  },
  deliveryTime: undefined,
};

const goodsReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ADD_GOOD: {
      const { item } = action.payload;
      const newState = {
        ...state,
        goods: addItemToCart(state.goods, item)
      }
      saveCart(newState)
      return newState
    }

    case ADD_GOODS: {
      const newState = {
        ...state,
        goods: action.payload
      }
      saveCart(newState)
      return { ...newState }
    }

    case CHANGE_QUANTITY: {
      const { id, quantity } = action.payload;
      const newState = {
        ...state,
        goods: changeItemQuantity(state.goods, id, quantity)
      }
      saveCart(newState)
      return newState
    }

    case REMOVE_GOOD: {
      const { id } = action.payload;
      const newState = {
        ...state,
        goods: removeArrayItem(state.goods, id),
      }
      saveCart(newState)
      return newState
    }

    case REFRESH_DATA: {
      const newState = {
        ...state,
        goods: {},
      }
      saveCart(newState)
      return newState
    }

    case CLEAR_CART: {
      const newState = {
        ...state,
        goods: {},
      }
      saveCart(newState)
      return newState
    }

    case SET_PICKUP_DATE: {
      const time = state.pickupTimeStores[action.payload.storeId] || state.pickupTime
      const pickupTime = {
        from: getPickupDate(
          time,
          action.payload.time
        ),
        to: time.to
      }
      const newState = {
        ...state,
        pickupTime,
        pickupTimeStores: {
          ...state.pickupTimeStores,
          [action.payload.storeId]: pickupTime,
        },
      }
      saveCart(newState)
      return newState
    }

    case SET_PICKUP_TIME: {
      const pickupTime = getPickupTime(
        state.pickupTimeStores[action.payload.storeId] || state.pickupTime,
        action.payload.time
      )
      const newState = {
        ...state,
        pickupTime,
        pickupTimeStores: {
          ...state.pickupTimeStores,
          [action.payload.storeId]: pickupTime,
        },
      }
      saveCart(newState)
      return newState
    }

    case SET_DELIVERY_TIME: {
      const newState = {
        ...state,
        deliveryTime: action.payload,
      }
      saveCart(newState)
      return newState
    }

    case SET_DELIVERY_TIME: {
      const newState = {
        ...state,
        deliveryTime: action.payload,
      }
      saveCart(newState)
      return newState
    }

    case SET_STATE_INIT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case CLEAR_CART_ALL: {
      localStorage.clear('cart')
      return {
        ...initialState,
      }
    }

    default: {
      return state;
    }
  }
};

export default goodsReducer;
