import request from './base'

export const getProducts = (filter = {}, page, sort, withCount) => {
  return request({
    url: `/products`,
    params: {
      filter,
      page,
      sort,
      with_count: withCount,
    }
  })
}

export const getBoughtProducts = () => {
  return request({
    url: `/products`,
    params: {
      filter: { category: 'BUY_AGAIN_CATEGORY' },
    }
  })
}

export const getRecommendedProducts = () => {
  return request({
    url: `/products/recommended`,
  })
}

