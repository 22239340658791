import {
  ADD_GOOD,
  ADD_GOODS,
  CHANGE_QUANTITY,
  REMOVE_GOOD,
  REFRESH_DATA,
  CLEAR_CART,
  CLEAR_CART_ALL,
  SET_PICKUP_DATE,
  SET_PICKUP_TIME,
  SET_STATE_INIT,
  SET_DELIVERY_TIME,
} from './constants';

export const addGood = good => ({
  type: ADD_GOOD,
  payload: good,
})

export const addGoods = goods => ({
  type: ADD_GOODS,
  payload: goods,
})

export const changeQuantity = (id, quantity) => ({
  type: CHANGE_QUANTITY,
  payload: {
    id,
    quantity,
  },
});

export const removeGood = (id) => ({
  type: REMOVE_GOOD,
  payload: { id },
});

export const refreshData = () => ({
  type: REFRESH_DATA,
});

export const clearCart = storeId => ({
  type: CLEAR_CART,
})

export const setPickupDate = (time, storeId) => ({
  type: SET_PICKUP_DATE,
  payload: {
    time, storeId
  },
});

export const setPickupTime = (time, storeId) => ({
  type: SET_PICKUP_TIME,
  payload: {
    time, storeId
  },
});

export const setDeliveryTime = time => ({
  type: SET_DELIVERY_TIME,
  payload: time,
});

export const setStateInit = cart => ({
  type: SET_STATE_INIT,
  payload: cart,
})

export const clearCartAll = () => ({
  type: CLEAR_CART_ALL,
})
