import {
  TOGGLE_NEARBY_STORE,
  TOGGLE_WARNING,
  TOGGLE_PICKUP_CALENDAR,
  TOGGLE_CART,
  TOGGLE_LOGIN,
  TOGGLE_SIGNUP,
  TOGGLE_FORGOT_PASSWORD,
  TOGGLE_HELP,
  TOGGLE_HOW_IT_WORK,
} from './constants'

export const initState = {
  nearbyStore: {
    open: false,
    zipCode: '',
  },
  warning: {
    open: false,
    withCancel: false,
    callback: () => { },
    message: '',
    okText: 'OK',
    cancelText: 'Cancel',
  },
  pickupCalendar: {
    open: false,
  },
  cart: {
    open: false,
  },
  login: {
    open: false,
  },
  signup: {
    open: false,
  },
  forgotPassword: {
    open: false,
  },
  help: {
    open: false,
  },
  howItWork: {
    open: false,
  }
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case TOGGLE_NEARBY_STORE:
      return { ...state, nearbyStore: payload }

    case TOGGLE_WARNING:
      return { ...state, warning: payload }

    case TOGGLE_PICKUP_CALENDAR:
      return { ...state, pickupCalendar: payload }

    case TOGGLE_CART:
      return { ...state, cart: payload }

    case TOGGLE_LOGIN:
      return { ...state, login: payload }

    case TOGGLE_SIGNUP:
      return { ...state, signup: payload }

    case TOGGLE_FORGOT_PASSWORD:
      return { ...state, forgotPassword: payload }

    case TOGGLE_HELP:
      return { ...state, help: payload }

    case TOGGLE_HOW_IT_WORK:
      return { ...state, howItWork: payload }

    default:
      return state
  }
}