import React, { useState, memo } from 'react'

import { Collapse } from 'reactstrap'
import { List, ListItem, ListItemText } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';

export default memo(({
  subcategories = [],
  open,
  onSelect,
  selectedIndex,
}) => {
  const [selectedSubcateIndex, setSelectedSubcateIndex] = useState(selectedIndex || -1)
  const onSelectSubcate = async index => {
    await setSelectedSubcateIndex(index)
    onSelect(index)
  }
  return (
    <Collapse in={true} isOpen={open}>
      <List component="div" disablePadding>
          {subcategories.map((subcategory, subIndex) => (
            <React.Fragment key={'list-button' + subcategory.label + subIndex}>
              <ListItem
                button
                key={'list-item' + (subcategory.label)}
                onClick={() => onSelectSubcate(subIndex)}
                selected={selectedSubcateIndex === subIndex}
                className='subcategories-list-item'
              >
                <ListItemText
                  primary={subcategory.label}
                  className='subcategories-list-item-text'
                />
              </ListItem>
              {subIndex !== subcategories.length - 1 && <Divider />}
            </React.Fragment>
        ))}
      </List>
    </Collapse>
  )
})