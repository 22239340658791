
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import './_styles.scss'

import {
  Navbar,
  NavbarBrand,
} from 'reactstrap'

import Authenticated from './Authenticated/Header'
import Unauthenticated from './Unauthenticated/Header'

import BrandIcon from './images/brand.png'


const Header = ({
  user,
}) => {
  const history = useHistory()

  const [authenticated, setAuthenticated] = useState(false)

  const [isOpen, setIsOpen] = useState(false)
  const toggle = (event) => {
    if (event) {
      // fired by collapse component
      event && event.stopPropagation()
      setIsOpen(!isOpen)
    } else {
      // fired from other components
      if (isOpen) setIsOpen(false)
    }
  }
  const onLogoClick = () => {
    setIsOpen(false)
    if (history.location.pathname === '/store') {
      history.replace({
        search: ''
      })
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    if (user.token) {
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
    }
  }, [user])

  let comp
  if (authenticated) {
    comp = <Authenticated toggle={toggle} isCollapseOpen={isOpen} />
  } else {
    comp = <Unauthenticated toggle={toggle} isCollapseOpen={isOpen} />
  }
  return (
    <div id='Header'>
      <Navbar color="white" light expand="md">
        <NavbarBrand
          onClick={onLogoClick}
        >
          <img src={BrandIcon} alt="logo" className="w-100" />
        </NavbarBrand>
        {comp}
      </Navbar>
    </div >
  )
}

const mapStateToProps = ({ user }) => ({
  user,
})

export default connect(mapStateToProps)(Header)