import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'

export default (Component) => props => {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, hash)
  return <Component {...props} />
}