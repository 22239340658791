import React from 'react'
import { Route, Redirect } from 'react-router'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => (
            localStorage.getItem('token')
                ? <Component {...props} />
                : <Redirect to='/home' />
        )} />)
}

export default ProtectedRoute
