import React, { useState, useEffect, useCallback, memo } from 'react';
import _ from 'lodash'
import { connect } from 'react-redux'

import ProductList from './ProductList';

import {
  togglePickupCalendar as togglePickupCalendarState,
} from 'reducers/modals/actions'
import { setCategories } from 'reducers/category/actions'
import { openToast as openToastState } from 'reducers/toast/actions'
import { addGood, changeQuantity } from 'reducers/cart/actions'

import { updateCart } from 'services/cart'

const ProductLists = memo(({
  // productCount,
  productsData,
  category,
  currentPage,
  onPageChange,
  searchKeyword,
  // state
  cart,
  addItem,
  openToast,
  changeItemQuantity,
  search,
  searchSelectedCates,
  setSearchSelectedCates,
  onSelectProduct,
  currentSort,
}) => {
  const [productsDataWithCart, setProductsDataWithCart] = useState(productsData)

  const requestUpdateCart = useCallback(_.debounce((goods) => {
    const payload = Object.values(goods).map(({ _id, quantity = 1 }) => ({ id: _id, quantity }))
    updateCart(payload)
  }, 1000), [])

  const updateProductsWithCart = useCallback(() => {
    const productsWithCart = productsData.products.map(product => {
      if (cart.goods[product._id]) {
        product.quantity = cart.goods[product._id].quantity
      } else {
        delete product.quantity
      }
      return { ...product }
    })
    setProductsDataWithCart({
      count: productsData.count,
      products: productsWithCart,
    })
  }, [cart.goods, productsData])

  useEffect(() => {
    updateProductsWithCart()
  }, [updateProductsWithCart])
  
  useEffect(() => {
    requestUpdateCart(cart.goods)
  }, [cart, requestUpdateCart])

  const addToCart = async (product) => {
    addItem(product)
  }
  const onAdd = (product) => {
    if (product.inventory >= 0 && product.inventory <= product.quantity) {
      // not check if inventory is -1 or undefined
      return openToast(`Only ${product.inventory} available`)
    }
    const newQuantity = product.quantity + 1
    changeItemQuantity(product._id, newQuantity)
  }
  const onRemove = (product) => {
    const newQuantity = product.quantity > 0 ? (product.quantity - 1) : 0
    changeItemQuantity(product._id, newQuantity)
  }

  return (
    <>
      <ProductList
        productsData={productsDataWithCart}
        category={category}
        currentPage={currentPage}
        onPageChange={onPageChange}
        searchKeyword={searchKeyword}
        onProductClick={onSelectProduct}
        addToCart={addToCart}
        onAdd={onAdd}
        onRemove={onRemove}
        search={search}
        searchSelectedCates={searchSelectedCates}
        setSearchSelectedCates={setSearchSelectedCates}
        currentSort={currentSort}
      />
    </>
  )
}, (prevProps, nextProps) => {
  return prevProps.productsData === nextProps.productsData &&
    prevProps.cart === nextProps.cart
})

const mapStateToProps = ({ cart }) => ({
  cart,
})
const mapDispatchToProps = dispatch => ({
  addItem: (item) => dispatch(addGood({ item })),
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity)),
  togglePickupCalendar: open => dispatch(togglePickupCalendarState({ open })),
  setCategoriesState: categories => dispatch(setCategories(categories)),
  openToast: (message) => dispatch(openToastState({ message, type: 'info' })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductLists)
