import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Section1 from './components/section1'
import Section2 from './components/section2'
import Section3 from './components/section3'
import Section4 from './components/section4'
import Section5 from './components/section5'
import Section6 from './components/section6'

import './_Landing.scss'

const Landing = ({ user }) => {
  const history = useHistory()
  useEffect(() => {
    if (user.token) {
      history.push('/store')
    }
  }, [history, user])
  return (
    <div className='home-page'>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </div>
  )
}

const mapStateToProps = ({ store, user }) => ({
  store: store.selectedStore,
  user: user,
})
export default connect(mapStateToProps)(Landing)
