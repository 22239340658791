import React from 'react'

import InquiryForm from './components/inquery-form'
import './styles.scss'

import PlaneImage from './images/plane.svg'

const HomeSection6 = () => {
  return (
    <div className='home-section6' id="inquiry-form">
      <div className='home-section6-wrapper'>
        <h3 className="main-text">Have an inquiry?</h3>
        <h2 className="sub-text">Let's Get In Touch Now</h2>
      </div>
      <div className="inquiry-form">
        <InquiryForm />
      </div>
      <div className="home-section6-background" />
      <img src={PlaneImage} alt="plane" />
    </div>
  )
}

export default HomeSection6