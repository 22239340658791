import React from 'react'
import { connect } from 'react-redux'

import Button from 'components/common/button'
import './styles.scss'

import Image1 from './images/Asset1.png'
import GreenRect from './images/Rectangle_365.png'

import {
  toggleLogin as toggleLoginState,
} from 'reducers/modals/actions'

const HomeSection1 = ({ toggleLogin }) => {
  return (
    <div className='home-section1'>
      <div className="green-background" />
      <div className="green-rect">
        <img src={GreenRect} alt="green rect" />
      </div>
      <h1 className="main-text">Groceries Delivered Stress Free</h1>
      <h5 className="sub-text">Multi-family grocery delivery solution</h5>
      <Button
        classes="success"
      // onClick={() => toggleLogin(true)}
      >
        <a href="#inquiry-form">Get Started</a>
      </Button>
      {/* <a
        
        className="success"
        onClick={() => toggleLogin(true)}
        href="#inquiry-form"
      >
        Get Started
      </a> */}
      <img src={Image1} className="main-image" alt="section1 background" />
    </div>
  )
}

const mapStateToProps = ({ modals }) => ({
  openLogin: modals.login.open,
})
const mapDispatchToProps = dispatch => ({
  toggleLogin: open => dispatch(toggleLoginState({ open })),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeSection1)