import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import Input from 'components/common/inputs/style3'
import DataChanged from 'components/common/data-changed'

import { renderMoney } from 'utils/helper'
import { getMappedItemStatus } from 'utils/helper'

import {
  Create as CreateIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons'

import { toggleWarning } from 'reducers/modals/actions'

const ActionItem = ({ Icon, color, onClick }) => {
  return (
    <div onClick={onClick} className={`item-action ${color}`}>
      {Icon}
    </div>
  )
}

const NotEditingItem = ({ item, index, onEditing, editable }) => {
  return (
    <tr key={index}>
      {/* <td colSpan="1">{index + 1}</td> */}
      {/* <td>
        <StatusBadge content={item.status} style={{
          width: '8rem',
        }} />
      </td> */}
      <td colSpan="1">
        <div className="dropdown">
          <button
            className={`btn btn-secondary dropdown-toggle ${item.status}`}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {getMappedItemStatus(item.status)}
          </button>
        </div>
      </td>
      <td colSpan="2">
        <h2>{item.product.name}</h2>
      </td>
      <td colSpan="1">
        {item.pricePerUnit ? item.weight ? <DataChanged
          oldData={item.unitValue * item.quantity}
          newData={item.weight}
        /> : item.unitValue : item.unitValue}
        &nbsp;{item.unit}
      </td>
      <td colSpan="1">
        ${renderMoney(item.price)}
      </td>
      <td colSpan="1" class='d-flex'>
        <span class="align-self-end pr-3">{item.quantity}</span>
        {editable && (
          <div className='item-actions'>
            <ActionItem
              Icon={<CreateIcon />}
              onClick={onEditing}
            />
          </div>
        )}
      </td>
      <td colSpan="1">
        ${renderMoney(item.finalTax ?? item.tax)}
      </td>
      <td colSpan="1">
        ${renderMoney(item.finalTotal ?? item.total)}
      </td>
      {/* <td>
        {editable && (
          <div className='item-actions'>
            <ActionItem
              Icon={<CreateIcon />}
              onClick={onEditing}
            />
          </div>
        )}
      </td> */}
    </tr>
  )
}

const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: true, callback, message })),
})

const EditingItem = connect(null, mapDispatchToProps)(({ item, index, cancelEdit, updateItem, openWarningModal }) => {
  const [tempItem, setTempItem] = useState({ ...item })
  const { register, errors } = useForm({});
  const onFieldChange = (field, value) => {
    setTempItem({
      ...tempItem,
      [field]: value,
    })
  }
  const submitEdit = (newItem = tempItem) => {
    const payload = {
      status: newItem.status !== item.status ? newItem.status : undefined,
      weight: newItem.weight !== item.weight ? newItem.weight : undefined,
      quantity: newItem.quantity !== item.quantity ? newItem.quantity : undefined,
      unitValue: newItem.unitValue !== item.unitValue ? newItem.unitValue : undefined,
    }
    updateItem(payload)
  }

  const openWarningRemoveItem = () => {
    const item = {
      ...tempItem,
      quantity: 0,
    }
    openWarningModal({
      message: 'Are you sure to remove this item?',
      callback: () => submitEdit(item),
    })
  }
  return (
    <tr key={index}>
      <td colSpan="1">
        <div className="dropdown">
          <button
            className={`btn btn-secondary dropdown-toggle ${tempItem.status}`}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {getMappedItemStatus(tempItem.status)}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7.678"
              height="4.886"
              viewBox="0 0 7.678 4.886"
            >
              <path
                id="Path_360"
                data-name="Path 360"
                d="M6.439,9.486,2.6,5.606,3.6,4.6,6.439,7.474,9.282,4.6l1,1.006Z"
                transform="translate(-2.6 -4.6)"
                fill="#fff"
              />
            </svg>
          </button>
        </div>
      </td>
      <td colSpan="2">
        <h2>{tempItem.product.name}</h2>
      </td>
      <td colSpan="1">{tempItem.unitValue} {tempItem.unit}</td>
      <td colSpan="1">${renderMoney(tempItem.price)}</td>
      <td colSpan="1" class='d-flex input-qty'>
        <Input
          type="number"
          step="1"
          min="1"
          // placeholder='Quantity'
          inputRef={register({
            required: true,
          })}
          name="quantity"
          defaultValue={tempItem.quantity}
          className={errors.unitValue && 'error'}
          onChange={e => onFieldChange('quantity', e.target.value)}
        />
        <div className='item-actions'>
          <ActionItem
            Icon={<DeleteForeverIcon />}
            color='grey'
            onClick={openWarningRemoveItem}
          />
          <ActionItem
            Icon={<CheckIcon />}
            color='green'
            onClick={() => submitEdit()}
          />
          <ActionItem
            Icon={<ClearIcon />}
            color='red'
            onClick={cancelEdit}
          />
        </div>
      </td>
      <td colSpan="1">${renderMoney(tempItem.finalTax ?? tempItem.tax)}</td>
      <td colSpan="1">${renderMoney(tempItem.finalTotal ?? tempItem.total)}</td>
      {/* <td>
        <div className='item-actions'>
          <ActionItem
            Icon={<CheckIcon />}
            color='green'
            onClick={submitEdit}
          />
          <ActionItem
            Icon={<ClearIcon />}
            color='red'
            onClick={cancelEdit}
          />
        </div>
      </td> */}
    </tr>
  )
})

const OrderItem = ({ item, updateItem, index, orderAllowedEdit, openWarningModal }) => {
  const [editing, setEditing] = useState(false)

  const onUpdateItem = (data) => {
    setEditing(false)
    updateItem(item._id, data)
  }
  return editing ? <EditingItem
    item={item}
    updateItem={onUpdateItem}
    cancelEdit={() => setEditing(false)}
    index={index}
  /> : <NotEditingItem
    item={item}
    onEditing={() => setEditing(true)}
    index={index}
    editable={orderAllowedEdit}
  />
}

export default OrderItem
