import React from 'react'

import './styles.scss'

const HeaderCart = ({ itemCountInCart }) => {
  return (
    <div className='header-item header-cart'>
      <div className='header-item-icon'>
        <img src='/icons/noun_cart.svg' alt='cart' />
        {itemCountInCart > 0 && (
          <div className='cart-badge'>
            <span>
              {itemCountInCart}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderCart