import React, { useState } from "react";
import qs from 'query-string'
import Button from "../../common/button";
import InputStyle2 from 'components/common/inputs/style2'
import "./_styles.scss";

// network
import { resetPassword } from 'services/user'

const ResetPassword = ({
  location, onDone
}) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [error, setError] = useState('')

  const onPasswordChange = e => {
    setPassword(e.target.value)
  }
  const onConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value)
  }
  const onSubmit = async () => {
    if (confirmPassword !== password)
      return setError('Passwords do not match')
    const {
      email,
      token
    } = qs.parse(location.search)
    const data = await resetPassword(email, token, password)
    if (data.result !== 'success') {
      return setError(data.err)
    }
    onDone()
  }

  return (
    <div role="dialog" className='reset-password-container'>
      <div className="login_header text-center">
        <h4 className="mb-3">Reset your password</h4>
        <p>Please enter your new password.</p>
      </div>
      <div className="modal-body">
        <div className="form mt-3">
          <div>
            <div className="input-group form-group position-relative">
              <InputStyle2 onChange={onPasswordChange} type="password" placeholder="Password" />
            </div>
            <div className="input-group form-group position-relative">
              <InputStyle2 onChange={onConfirmPasswordChange} type="password" placeholder="Confirm Password" />
            </div>
            {error && (
              <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            )}
            <div className="form-group submit_button mt-5">
              <Button
                classes='success'
                style={{
                  height: '56px',
                  fontSize: '13px',
                  fontWeight: 600,
                }}
                onClick={onSubmit}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword
