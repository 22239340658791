import React, { useState, memo, useCallback } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { Collapse, ButtonDropdown, DropdownToggle } from 'reactstrap';

import SideBarSubCate from './components/SideBarSubCate'

import './_styles.scss'

export default memo(function SideBarList({
  categories = [],
  onChooseCate,
  onChooseSubcate,
  notSelect,
  selectedIndex,
  selectedSubcateIndex,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isCollapsing, setIsCollapsing] = useState(false)
  const [open, setOpen] = useState(false)

  const toggle = useCallback((toggle) => {
    toggle = toggle || !isCollapsed
    setIsCollapsed(toggle)
    setIsCollapsing(true)
    setTimeout(() => {
      setIsCollapsing(false)
    })
  })
  const handleClick = async (index) => {
    if (index === selectedIndex) {
      setOpen(!open)
      return
    }
    setOpen(true)
    // setSelectedIndex(index)
    if (selectedSubcateIndex !== -1) {
      // unselect subcate
      // setSelectedSubcateIndex(-1)
      onChooseSubcate(-1)
    }
    onChooseCate(index)
    toggle(true)
  };
  const handleClickSubcategory = useCallback(async (index) => {
    // setSelectedSubcateIndex(index)
    onChooseSubcate(index)
    toggle(true)
  }, [onChooseSubcate, toggle])

  return (
    <div className='categories'>
      <div className='navbar-expand-md'>
        <ButtonDropdown
          color="primary"
          toggle={() => toggle()}
          className='expand-btn'
        >
          <DropdownToggle caret color="primary">
            {categories[selectedIndex]?.label || 'Select...'}
            {/* {category && category.label} */}
          </DropdownToggle>
        </ButtonDropdown>
        <div
          // style={{ display: isCollapsed ? 'none' : 'block' }}
          className={`cust-collapse ${isCollapsing ? 'cust-collapsing' : ''} ${isCollapsed ? 'cust-collapsed' : ''}`}
        >
          <Collapse isOpen={true}>
            <List className="w-100">
              {categories.map((category, index) =>
                <React.Fragment key={'list-button' + category.label + index}>
                  <ListItem
                    button
                    onClick={() => handleClick(index)}
                    selected={!notSelect && index === selectedIndex}
                    className='categories-list-item'
                  >
                    <ListItemText
                      primary={category.label}
                      className='categories-list-item-text'
                    />
                    {category.subcategories.length > 0 && (!notSelect && selectedIndex === index && open ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small'/>)}
                  </ListItem>
                  <SideBarSubCate
                    subcategories={category.subcategories}
                    open={!notSelect && open && selectedIndex === index}
                    onSelect={handleClickSubcategory}
                    selectedIndex={selectedSubcateIndex}
                  />
                  {index !== categories.length - 1 && <Divider />}
                </React.Fragment>
              )}
            </List>
          </Collapse>
        </div>
      </div>
    </div>
  )
})
