import React from 'react'

import './styles.scss'

const InputStyle3 = ({ type, placeholder, inputRef, name, ...props }) => {
  return (
    <div className='input-style-3'>
      <input
        type={type}
        placeholder={placeholder}
        ref={inputRef}
        name={name}
        {...props}
      />
    </div>
  )
}

export default InputStyle3