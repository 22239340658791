import React, { useRef, useEffect } from 'react'
import Button from 'components/common/button'

import SearchIcon from './icons/search.svg'
import './styles.scss'

const NavigatorSearch = ({ search, value }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    inputRef.current.value = value
  }, [value])
  const onSearch = (e) => {
    e.preventDefault()
    search(inputRef.current.value)
  }
  return (
    <form className='store-search-bar-search' onSubmit={onSearch}>
      <div className='store-search-bar-search-input'>
        <img src={SearchIcon} alt='search' />
        <input
          placeholder='Search for a product ...'
          ref={inputRef}
        />
      </div>
      <Button
        classes="success"
        onClick={onSearch}
      >
        Search
      </Button>
    </form>
  )
}

export default NavigatorSearch