import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reducers from './reducers'
import middleware from './middleware'

export const store = createStore(
    combineReducers({ ...reducers }),
    composeWithDevTools({ trace: true })(applyMiddleware(...middleware))
)

export const dispatch = store.dispatch
