import React from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  select: {
    '&:focus': {
      borderRadius: '5px',
      backgroundColor: '#fff',
    },
    border: 'solid 1px #D8D8D8',
    borderRadius: '5px',
    padding: '10px 30px 10px 15px',
    minWidth: '50px',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  option: {
    padding: '5px 10px',
    cursor: 'pointer',
  },
  menuItem: {
    fontSize: '1rem'
  },
  menuItemSelected: {
    backgroundColor: '#fff'
  },
  label: {
    display: 'contents'
  }
}));

export default function SelectMulti(props) {
  const { name, values, label, options = [], handleChange, onOpen, onClose } = props;
  const classes = useStyles();
  const onChange = (e) => {
    handleChange(e.target.value)
  }
  return (
    <div className={classes.root}>
      <Select
        classes={{
          root: classes.select,
        }}
        multiple
        displayEmpty
        disableUnderline={true}
        value={values}
        name="select"
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
        inputProps={{ 'aria-label': label }}
        renderValue={(selected) => `${name}${selected.length > 0 ? ` (${selected.length})` : ''}`}
      >
        {
          options.map((option, index) =>
            <MenuItem
              key={option.value + "-" + index}
              value={option.value}
              classes={{
                root: classes.menuItem,
                selected: classes.menuItemSelected,
              }}
              disableRipple
            >
              <Checkbox
                checked={values.includes(option.value)}
                color="primary"
                disableRipple
              />
              <option className={classes.label}>{option.label}</option>
            </MenuItem>
          )
        }
      </Select>
    </div>
  )
}