import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const CartEmpty = () => (
  <section className="cart-empty">
    <h3 className="cart-empty__title">
      Your cart is empty.
    </h3>
  </section>
);

export default CartEmpty;
