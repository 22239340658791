import React from 'react'

import './styles.scss'

import HowItWorkCard from './components/how-it-works-card'

import Image1 from './images/item1.svg'
import Image2 from './images/item2.svg'
import Image3 from './images/item3.svg'

const HomeSection5 = () => {
  const items = [
    {
      image: Image1,
      title: 'Shop',
      content: 'Purchase all your favorite groceries and products on Cart & Order at the comfort of your own home.'
    },
    {
      image: Image2,
      title: 'Schedule',
      content: 'Select the day and time your building gets deliveries.'
    },
    {
      image: Image3,
      title: 'Sit Back and Relax',
      content: 'Our dedicated team will gather all your items and carefully deliver it to your door.'
    }
  ]
  return (
    <div className='home-section5'>
      <div className='home-section5-wrapper'>
        <h2 className="main-text">How It Works</h2>
        <p className="sub-text">Groceries reliably delivered to residents. We pick, package, and deliver everything you order on Cart & Order.</p>
        <div className="card-list">
          {items.map(item => (
            <HowItWorkCard {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeSection5