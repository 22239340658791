export const ADD_GOOD = '@cart/ADD_GOOD';
export const ADD_GOODS = '@cart/ADD_GOODS';
export const CHANGE_QUANTITY = '@cart/CHANGE_QUANTITY';
export const REMOVE_GOOD = '@cart/REMOVE_GOOD';
export const REFRESH_DATA = '@cart/REFRESH_DATA';
export const CLEAR_CART = '@cart/CLEAR_CART';
export const CLEAR_CART_ALL = '@cart/CLEAR_CART_ALL';

export const SET_PICKUP_DATE = '@cart/SET_PICKUP_DATE';
export const SET_PICKUP_TIME = '@cart/SET_PICKUP_TIME';

export const SET_DELIVERY_TIME = '@cart/SET_DELIVERY_TIME';

export const SET_STATE_INIT = '@cart/SET_STATE_INIT'
