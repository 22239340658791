import React from 'react';
import { connect } from 'react-redux'

import BaseModal from 'components/common/modal'

import { toggleWarning } from 'reducers/modals/actions'

import Accordion from './accordion'
import HelpForm from './form'

import './_styles.scss'

const HelpModal = ({
  open,
  handleClose,
}) => {
  const onSubmitted = async () => {
    handleClose()
  }
  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      className='help-modal'
    >
      <div className="modal-body">
        <div className="text-center">
          <h4 className="mb-3">FAQ</h4>
        </div>
        <Accordion />
        <div className="text-center">
          <h4 className="mb-3">Need help with your order or other inquiries?<br/>Please let us know.</h4>
        </div>
        <HelpForm onSubmitted={onSubmitted} />
      </div>
    </BaseModal>
  );
}

const mapDispatchToProps = dispatch => ({
  openWarningModal: ({ callback, message }) => dispatch(toggleWarning({ open: true, withCancel: false, callback, message }))
})

export default connect(null, mapDispatchToProps)(HelpModal)
