export const addItemToCart = (oldGoods, item) => {
  const goods = { ...oldGoods }
  if (goods[item._id]) {
    goods[item._id].quantity++
  } else {
    item.quantity = 1
    goods[item._id] = item
  }
  return { ...goods }
}

export const changeItemQuantity = (goods, id, quantity) => {
  quantity = quantity < 0 ? 0 : quantity
  if (goods[id]) {
    goods[id].quantity = quantity
    if (goods[id].quantity === 0) delete goods[id]
  }
  return { ...goods }
}

export const getPickupDate = (pickupTime, date) => {
  const from = new Date(pickupTime.from || null)
  from.setDate(date.getDate())
  from.setMonth(date.getMonth())
  from.setYear(date.getFullYear())
  return from
}

export const getPickupTime = (pickupDate, time) => {
  const from = new Date(pickupDate.from)
  if (time.from) {
    from.setHours(time.from.getHours())
    from.setMinutes(time.from.getMinutes())
  }
  let to
  if (time.to) {
    to = new Date(from)
    to.setHours(time.to.getHours())
    to.setMinutes(time.to.getMinutes())
  }
  return { from, to }
}

export const saveCart = cart => {
  localStorage.setItem('cart', JSON.stringify(cart))
}

export const clearStoreCart = (cart, storeId) => {
  let newCart = {}
  for (let key in cart) {
    if (cart[key].store !== storeId) newCart[key] = cart[key]
  }
  newCart = { ...newCart }
  const products = Object.values(newCart)
  const nextStoreId = products.length > 0 ? products[0].store : undefined
  return {
    store: {
      _id: nextStoreId,
    },
    cart: newCart,
  }
}