import React from "react"

import "./styles.scss"

import Item1 from "./images/item1.png"
import Background1 from "./images/background1.png"
import Item2 from "./images/item2.png"
import Background2 from "./images/background2.png"
import TextConnerImage from "./images/Group_2559.svg"

const Section3 = () => {
  return (
    <div className="home-section3">
      <div className="home-section3-layout">
        <div className="home-section3-layout-text">
          <div>
            <h3>Time Saving</h3>
            <p>We are reliable, efficient, and convenient. Let us do the heavy lifting while residents spend more time doing the things they love most.</p>
          </div>
          <img src={TextConnerImage} alt="" />
        </div>
        <div className="home-section3-layout-img flex-end">
          <img src={Item1} alt="time saving" />
          <div className="home-section3-layout-img-anchor">
            <img src={Background1} alt="time saving background" />
          </div>
        </div>
      </div>
      <div className="home-section3-layout home-section3-layout-revert">
        <div className="home-section3-layout-text flex-end">
          <div>
            <h3>Money Saving</h3>
            <p>We are completely transparent in our pricing. Most delivery services don’t show you the total until payment. We have NO “SERVICE FEES” and TIPS are not necessary!  This means more money for residents to spend on the things they need and want to enjoy.</p>
          </div>
          <img src={TextConnerImage} alt="" className="right" />
        </div>
        <div className="home-section3-layout-img">
          <img src={Item2} alt="money saving" className="left-padding" />
          <div className="home-section3-layout-img-anchor">
            <img src={Background2} alt="time saving background" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section3