import React from 'react';

import { CircularProgress } from '@material-ui/core'

import './styles.scss';

const Button = ({ children, classes = '', style, onClick, disabled = false, submitting, ...props }) => {
  return (
    <button
      className={`button ${classes}`}
      onClick={onClick} style={style}
      disabled={submitting || disabled}
      {...props}
    >
      {submitting ? (
        <CircularProgress color='default' size='20px' />
      ) : children}
    </button>
  );
}

export default Button;
