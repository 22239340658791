import React, { useEffect } from 'react'
import { Avatar } from '@material-ui/core'

import NavigatorSearch from './components/navigator-search'

import './_styles.scss'

// import FoodImg from './images/food.png'
// import ConvenienceStore from './images/convenience_store.jpeg'
// import LiquorStore from './images/liquor_store.jpeg'
// import VegetableStore from './images/vegetables_store.jpeg'
import StoreBanner from './images/store_banner.png'

export default ({
  // image,
  // name,
  // email,
  // storeType,
  buildingName,
  search,
  keyword,
}) => {
  let bgImg = StoreBanner
  // useEffect(() => {
  //   // const element = document.getElementsByTagName('main')[0]
  //   const onScolling = () => {
  //     const scrolled = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
  //     const container = document.getElementById('store-container')
  //     if (!container) return
  //     if (scrolled >= 210) {
  //       container.classList.add('fixed')
  //     } else {
  //       container.classList.remove('fixed')
  //     }
  //   }
  //   window.addEventListener('scroll', onScolling, false)
  //   return () => {
  //     window.removeEventListener('scroll', onScolling)
  //   }
  // }, [])
  const getElement = () => {
    return (
      <div className='store-name-wrapper' id='store-name-wrapper'>
        <div
          className='store-name-container'
          style={{
            backgroundImage: `url(${bgImg})`,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className='store-name-info'>
            <h3>{buildingName}</h3>
            <p>Groceries delivered to you at your pre-selected times.</p>
          </div>
          {/* <NavigatorSearch search={search} value={keyword} /> */}
        </div>
      </div>
    )
  }
  return getElement()
}