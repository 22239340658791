import React from 'react'

import './styles.scss'

const InputGroup = ({
  label,
  placeholder,
  value,
  onChange,
  onClick,
  type,
  pattern,
  required,
  note,
  disabled,
}) => {
  return (
    <div className='input-group' onClick={onClick}>
      {label && <p>{label}</p>}
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        pattern={pattern}
        required={required}
        autoComplete="off"
        disabled={disabled}
      />
      {note && (
        <p className="input-group-note">{note}</p>
      )}
    </div>
  )
}

export default InputGroup