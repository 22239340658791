import React from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch } from 'react-router'
import { ThemeProvider } from '@material-ui/core/styles';
// import { HashRouter } from 'react-router-dom'

import Landing from './components/Main/Landing1/Landing'
import Store from './components/Main/Store/Store'
import Checkout from './components/Main/Checkout/Checkout'
import Account from './components/Main/Account/Account'
import Orders from './components/Main/Orders/Orders'
import Receipt from './components/Main/Receipt/Receipt'
import ResetPassword from './components/Main/ResetPassword/ResetPassword'
import Privacy from './components/Main/Policies/Privacy'
import TermsAndConditions from './components/Main/Policies/TermsAndConditions'
import Header from './components/Navigation/Header/Header'
import Footer from './components/Navigation/Footer'
import NotFound from './components/Navigation/NotFound'
import ProtectedRoute from './components/Navigation/ProtectedRoute'

import WarningModal from 'components/common/modal/WarningModal'
import Snackbar from 'components/common/snackbar'
import ScrollToTop from 'components/Navigation/ScrollToTop'

import history from 'utils/history'

import { loadDataInit } from 'utils/state_init'
import { setAxiosDispatch } from 'utils/axios'
import { requestLocationPermission } from 'utils/permissions'
import theme from './theme'

import './services/user'

class App extends React.Component {
  constructor(props) {
    super(props)
    setAxiosDispatch(props.dispatch)
    // requestLocationPermission(props.dispatch)
    loadDataInit()
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <>
            <main className="container-fluid">
              <Header />
              <div id="main-body">
                <ScrollToTop />
                <Switch>
                  {/* <Route path="/store/:id" exact component={Store}></Route> */}
                  {/* <Route path="/store/:id/checkout" exact component={Checkout}></Route> */}
                  <ProtectedRoute path="/store" exact component={Store} />
                  <ProtectedRoute path="/checkout" exact component={Checkout} />
                  <ProtectedRoute path="/account" component={Account} />
                  <ProtectedRoute path="/orders" exact component={Orders} />
                  <ProtectedRoute path="/orders/:id" exact component={Receipt} />
                  <Route path="/reset-password" strict component={ResetPassword}></Route>
                  <Route path="/privacy" strict component={Privacy}></Route>
                  <Route path="/terms" strict component={TermsAndConditions}></Route>
                  <Route path="/" component={Landing}></Route>
                  <Route path="*" component={NotFound} exact={true} strict></Route>
                </Switch>
              </div>
              <Footer />
              <WarningModal />
              <Snackbar />
            </main>
          </>
        </Router>
      </ThemeProvider>
    )
  }
}

export default connect()(App)
