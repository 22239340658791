import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Fab,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import {
  Add,
  Remove,
} from '@material-ui/icons'
import Button from 'components/common/button'
import useStyles from './style';

import { ReactComponent as ShoppingCartIcon } from '../icons/shopping-cart-1.svg'

import { addGood, changeQuantity } from 'reducers/cart/actions'

const ProductPlaceHolderImg = '/images/product-placeholder.png'

const ProductCart = memo((props) => {
  const { product } = props;
  const classes = useStyles();
  const [quantity, setQuantity] = useState(product.quantity || 0);

  useEffect(() => {
    setQuantity(product.quantity || 0)
  }, [product.quantity, props])

  const addToCart = (e) => {
    e.stopPropagation()
    props.addToCart(product)
  }
  const onRemove = (e) => {
    e.stopPropagation()
    props.onRemove(product)
  }
  const onAdd = (e) => {
    e.stopPropagation()
    props.onAdd(product)
  }
  return (
    <Card className={`${classes.root} ${classes.cardBorder} product-card`} onClick={() => props.onClick(product)}>
      <CardMedia
        component="img"
        alt="Product"
        height="140"
        image={product.image || ProductPlaceHolderImg}
        title={product.name}
        className='product-card-image'
      />
      <CardContent className='product-card-title'>
        <Tooltip
          title={product.name}
        >
          <Typography variant="subtitle2" className='product-card-title-text'>
            {product.name}
          </Typography>
        </Tooltip>
      </CardContent>
      <CardContent className='product-card-meta'>
        <Typography variant="body1" className='product-card-meta-size'>
          {product.pricePerUnit && 'pkg '}{product.size}
        </Typography>
        <Typography variant="body1" className='product-card-meta-price'>
          ${product.price.toFixed(2)} {product.pricePerUnit && `/ ${product.unit}`}
        </Typography>
      </CardContent>
      {quantity <= 0 && <CardActions disableSpacing>
        <Fab
          // variant="extended"
          size="medium"
          color="default"
          aria-label="add"
          className="btn-add-to-cart product-actions"
          onClick={addToCart}
          style={{
            padding: 0,
          }}
          classes={{
            label: classes.storeButtonLabel,
          }}
          disabled={product.inventory === 0}
        >
          {product.inventory === 0 ? (<div className="product-action-extended-icon-text">
            Out of stock
          </div>) : (<div className="product-action-extended-icon-text">
            Add to cart
            </div>)}
        </Fab>
      </CardActions>}
      {quantity > 0 && <CardActions disableSpacing>
        <div className="product-actions">
          <div className="product-action-left-icon">
            <ShoppingCartIcon style={{
              fill: '#5FA01B',
            }} />
          </div>
          <div className="product-action-container">
            <IconButton onClick={onRemove} className="product-action-button" >
              <Remove color="secondary" className="product-action-button-icon" />
            </IconButton>
            <Typography className="product-count-typography" >{quantity}</Typography>
            <IconButton onClick={onAdd} className="product-action-button" >
              <Add color="secondary" className="product-action-button-icon" />
            </IconButton>
          </div>
        </div>
      </CardActions>}
    </Card>
  );
})

const mapDispatchToProps = dispatch => ({
  addItem: (item) => dispatch(addGood({ item })),
  changeItemQuantity: (id, quantity) => dispatch(changeQuantity(id, quantity)),
})

export default connect(null, mapDispatchToProps)(ProductCart)
