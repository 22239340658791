import React, { useState } from 'react';
import { connect } from 'react-redux'

import InputStyle2 from 'components/common/inputs/style2'
import ProgressButton from 'components/common/button/ProgressButton'

import { createHelpRequest } from 'services/common'
import { toggleWarning } from 'reducers/modals/actions'

const HelpForm = ({
  dispatch,
  onSubmitted,
}) => {
  const [error, setError] = useState()
  const [formData, setFormData] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    })
  }
  const validateForm = () => {
    if (!formData['firstName']) throw new Error('First name is missing')
    if (!formData['lastName']) throw new Error('Last name is missing')
    if (!formData['email']) throw new Error('Email name is missing')
    if (!formData['message']) throw new Error('Message is missing')
  }

  const submit = async () => {
    try {
      validateForm()
    } catch (err) {
      return setError(err.message)
    }
    setError('')
    setSubmitting(true)
    const data = await createHelpRequest(formData)
    setSubmitting(false)
    if (data.result !== 'success') {
      return
    }
    onSubmitted()
    dispatch(toggleWarning({
      open: true,
      withCancel: false,
      message: 'Your request is recorded.'
    }))
  }

  return (
    <>
      <div className="form mt-3">
        <div>
          <div className="input-group form-group position-relative input-group-horizontal">
            <InputStyle2 type="text" onChange={e => handleInputChange('firstName', e.target.value)} placeholder="First Name" />
            <InputStyle2 type="text" onChange={e => handleInputChange('lastName', e.target.value)} placeholder="Last Name" />
          </div>
          <div className="input-group form-group position-relative">
            <InputStyle2 type="text" onChange={e => handleInputChange('email', e.target.value)} placeholder="Email" />
          </div>
          <div className="input-group form-group position-relative">
            <InputStyle2
              type="text"
              onChange={e => handleInputChange('message', e.target.value)}
              placeholder="Message"
              textarea={true}
              rows={3}
            />
          </div>
          {error && (
            <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
          )}
          <div className="form-group submit_button mt-2">
            <ProgressButton
              classes='success'
              style={{
                height: '56px',
                fontSize: '13px',
                fontWeight: 600,
              }}
              onClick={submit}
              submitting={submitting}
            >
              Submit
              </ProgressButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect()(HelpForm)
