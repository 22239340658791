import request from './base'

export const getConstants = () => {
  return request({
    url: '/constants'
  })
}

export const createHelpRequest = (data) => {
  return request({
    method: 'POST',
    url: '/helps',
    data,
  })
}
