import React, { useState, useRef, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import './styles.scss';

const InputStyle4 = (props) => {
  const {
    label, errorMessage, type, step,
    error, refInput, placeholder,
    name, value, onChange,
    pattern,
  } = props;
  const refInputBlock = useRef();
  const [activeLabel, setActiveLabel] = useState(false);

  useEffect(() => {
    if (value) showActiveLabel()
  }, [value])
  const showActiveLabel = () => {
    refInputBlock.current.children[0].focus();
    setActiveLabel(true);
  }
  const closeActiveLabel = () => {
    if (!value) {
      setActiveLabel(false);
    }
  }
  const onBlur = () => {
    if (!value) {
      setActiveLabel(false);
    }
  }
  return (
    <OutsideClickHandler
      onOutsideClick={closeActiveLabel}
    >
      <div className="input-style-4">
        <label onClick={showActiveLabel} className={`input-style-4-label ${activeLabel ? 'input-style-4-labelFocused' : ''}`}>
          {label}
        </label>
        <div ref={refInputBlock}>
          <input
            className={`input-style-4-field ${error ? 'input-style-4-fieldError' : ''}`}
            placeholder={placeholder}
            type={type}
            ref={refInput}
            name={name}
            onFocus={showActiveLabel}
            onBlur={onBlur}
            onChange={onChange || undefined}
            step={step}
            autoComplete="new-password"
            pattern={pattern || '^[a-zA-Z0-9!@#$&()\\-`.+,/\" ]*$'}
            title="Only alphabet/numberic and a few special characters is allowed"
          />
        </div>
        {error && <div className="input-style-4-error">{errorMessage}</div>}
      </div>
    </OutsideClickHandler>
  );
}

export default InputStyle4;
